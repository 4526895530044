import { useContext, useEffect } from "react";
import ServiceContext from "../../context/serviceContext";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../helpers/utils";
import { Select } from "antd";

const ServiceSelector = () => {
  const navigate = useNavigate();
  const serviceContext = useContext(ServiceContext);

  useEffect(() => {
    const currService = localStorage.getItem("selectedService");
    serviceContext.setService(currService || "");
    setCookie("selected_service", currService || "");
  }, [serviceContext]);

  // route on service change
  const routeToService = (selectedService: string) => {
    if (selectedService === "parcel") {
      navigate("/parcel/overview");
    } else if (selectedService === "hiring") {
      navigate("/hiring/overview");
    } else if (selectedService === "bus") {
      navigate("/busride/overview");
    } else if (selectedService === "fuel") {
      navigate("/fuel/overview");
    } else if (selectedService === "hshs") {
      navigate("/hshs/overview");
    } else {
      console.log("Choose a service");
      navigate("/");
    }
  };

  const handleSelectChange = (selectedOption: any) => {
    console.log(selectedOption);
    serviceContext.setService(selectedOption);
    localStorage.setItem("selectedService", selectedOption);
    setCookie("selected_service", selectedOption);
    routeToService(selectedOption);
  };

  return (
    <div className="h-full">
      {/* <Select
        label="Select Service"
        onChange={(e: any) => handleSelectChange(e)}
        value={serviceContext.service}
      >
        <Option value="bus">Bus Ride</Option>
        <Option value="parcel">Parcel Service</Option>
        <Option value="hiring">Hiring Service</Option>
      </Select> */}
      <Select
        placeholder="Select..."
        style={{ width: "100%" }}
        value={serviceContext.service}
        onChange={handleSelectChange}
        options={[
          { value: "bus", label: "Bus Ride" },
          { value: "parcel", label: "Parcel Service" },
          // { value: "hiring", label: "Hiring Service" },
          { value: "fuel", label: "Fuel Service" },
          // { value: "hshs", label: "HSHS Service" },
        ]}
      />
    </div>
  );
};

export default ServiceSelector;
