import Select from "react-select";
import { useState, useEffect } from "react";
import { getFuelBackOfficeMSApi } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

interface chooseBranchProp {
  selectedBranch: any;
  onBranchChange: (value: any) => void;
}

const BranchSelector = ({
  selectedBranch,
  onBranchChange,
}: chooseBranchProp) => {
  const [isFetching, setIsFetching] = useState(false);
  const [branchsList, setBranchesList] = useState([]);
  const [branchNameInput, setBranchInput] = useState<any>("");

  const handleBranchChange = (value: any) => {
    onBranchChange(value || {});
  };

  const handleInputChange = (e: any) => {
    setBranchInput(e);
  };
  const fetchBranches = async () => {
    interface Filter {
      f: string;
      o: string;
      p: string[];
    }

    let defaultFilters: Filter[] = [];

    // Default filters
    defaultFilters.push({
      f: "name",
      o: "contains",
      p: [branchNameInput],
    });

    setIsFetching(true);

    try {
      const response = await getFuelBackOfficeMSApi(
        `${globalVariables.getFuelBranches}?limit=500&filters=${JSON.stringify(
          defaultFilters
        )}`
      );
      const mappedBranches = response?.payload?.items.map(
        (branch: any, i: number) => ({
          value: branch?.id,
          label: branch?.name,
        })
      );
      console.log(response);

      setBranchesList(mappedBranches);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchNameInput]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      placeholder="Select a branch"
      isSearchable={true}
      name="branchs"
      options={branchsList}
      isLoading={isFetching}
      value={selectedBranch}
      onChange={handleBranchChange}
      onInputChange={handleInputChange}
      isClearable
    />
  );
};

export default BranchSelector;
