import { useNavigate } from "react-router-dom";
import notFoundImg from "../images/404Img.svg";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <img className="w-[250px] lg:w-[400px]" src={notFoundImg} alt="404" />
        <div className="mt-4 flex flex-col items-center justify-center">
          <p className="font-semibold text-2xl">Oops! Page Not Found</p>

          <button
            className="px-4 py-2 bg-oya-ghana-red text-white mt-3"
            onClick={() => navigate("/busride/overview")}
          >
            Go Back Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
