import { useEffect, useMemo, useState } from "react";
import { Menu, Typography } from "@material-tailwind/react";
import AllTripsFilter from "./tripsFilter";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { globalVariables } from "../../../../../helpers/globarVars";
import { getApiWithMs, rideMsDownloadApi } from "../../../../../utils/api";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import moment from "moment";
import CardShimmer from "../../components/cardShimmer";
import { endOfDay, startOfDay } from "date-fns";
import { formatDateRange } from "../../../../../helpers/utils";
import { Box, Button, MenuItem } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Trip } from "./types";
import { APP_URL } from "../../../../../helpers/constants";
import { DownloadOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";

interface SelectedItem {
  value: number;
  lable: string;
}

const AllTripsList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [tripCode, setTripCode] = useState("");

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchId, setBranchId] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [companyId, setCompanyId] = useState(null);

  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [, setPeriod] = useState("");
  const [stationId, setStationId] = useState(null);
  const [useTestCompanies, setUseTestCompanies] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState(false);
  const [, setIsLoading] = useState(true);
  const [tripsTableData, setTripsTableData] = useState([]);
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [totalMinors, setTotalMinors] = useState(0);
  const [totalTrips, setTotalTrips] = useState(0);
  const [totalCapacities, setTotalCapacities] = useState(0);
  const [, setTotalCompanies] = useState(0);
  const [totalRoutes, setTotalRoutes] = useState(0);
  const [totalFare, setTotalFare] = useState(0);

  const [totalBranches, setTotalBranches] = useState(0);
  const [totalLoadingPoints, setTotalLoadingPoints] = useState(0);
  const [selectedFieldOfficer, setSelectedFieldOfficer] = useState<
    SelectedItem | any
  >();
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [busNumber, setBusNumber] = useState("");

  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [download, setDownload] = useState(false);

  const handleCompanyTestChange = (e: any) => {
    setUseTestCompanies(!useTestCompanies);
  };
  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  // handle onBranch select
  const onBranchSelect = (value: any) => {
    setSelectedBranch(value);
    setBranchId(value?.value);
  };

  const onCompanySelect = (value: any) => {
    setSelectedCompany(value);
    setCompanyId(value?.value);
    setSelectedBranch(null);
    setBranchId(null);
  };

  const onPointSelect = (value: any) => {
    setSelectedPoint(value?.value);
    setStationId(value?.value);
  };

  const onFieldOfficerSelect = (value: any) => {
    setSelectedFieldOfficer(value);
  };

  const handleTripCodeInput = (e: any) => {
    setTripCode(e.target.value);
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        interface Filter {
          f: string;
          o: string;
          p: any[];
        }

        let defaultFilters: Filter[] = [
          { f: "deleted_at", o: "is_null", p: [] },
        ];

        if (companyId) {
          defaultFilters = [
            ...defaultFilters,
            { f: "company.id", o: "=", p: [companyId] },
          ];
        }

        if (branchId) {
          defaultFilters = [
            ...defaultFilters,
            { f: "branch.id", o: "=", p: [branchId] },
          ];
        }
        if (defaultDates) {
          defaultFilters = [
            ...defaultFilters,
            {
              f: "departures_at",
              o: "between",
              p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
            },
          ];
        }

        if (selectedStatus) {
          defaultFilters = [
            ...defaultFilters,
            { f: "status", o: "contains", p: [selectedStatus] },
          ];
        }

        if (busNumber) {
          defaultFilters = [
            ...defaultFilters,
            { f: "bus.reg_number", o: "contains", p: [busNumber] },
          ];
        }

        if (tripCode) {
          defaultFilters = [
            ...defaultFilters,
            { f: "code", o: "contains", p: [tripCode] },
          ];
        }

        if (selectedFieldOfficer) {
          defaultFilters = [
            ...defaultFilters,
            {
              f: "field_officer.id",
              o: "=",
              p: [selectedFieldOfficer?.value],
            },
          ];
        }

        if (selectedPoint) {
          defaultFilters = [
            ...defaultFilters,
            {
              f: "loading_point.id",
              o: "=",
              p: [selectedPoint],
            },
          ];
        }

        if (useTestCompanies) {
          defaultFilters = [
            ...defaultFilters,
            {
              f: "company.for_test",
              o: "=",
              p: [useTestCompanies],
            },
          ];
        }

        if (download) {
          setIsFetching(true);
          const response = await rideMsDownloadApi(
            `${
              globalVariables.exportTrips
            }?sorting=departures_at:desc&filters=${JSON.stringify(
              defaultFilters
            )}`
          );
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute(
            "download",
            `trips-report-as-at-${moment().format()}.xlsx`
          );
          document.body.appendChild(link);
          //setTableLoadingState(false);

          link.click();
          setIsFetching(false);
          setDownload(false);
        } else {
          setIsFetching(true);
          const response = await getApiWithMs(
            `${
              globalVariables.getTripsRoute
            }?sorting=departures_at:desc&limit=${
              pagination.pageSize
            }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
          );

          setTripsTableData(response?.payload?.records?.items ?? []);
          setRowCount(response?.payload?.records?.total ?? 0);
          setTotalPassengers(response?.payload?.total_manifests ?? []);
          setTotalMinors(response?.payload?.total_minors ?? 0);
          setTotalTrips(response?.payload?.total_trips ?? 0);
          setTotalCapacities(response?.payload?.total_capacity ?? 0);
          setTotalCompanies(response?.payload?.total_companies ?? 0);
          setTotalBranches(response?.payload?.total_branches ?? 0);
          setTotalLoadingPoints(response?.payload?.total_loading_points ?? 0);
          setTotalRoutes(response?.payload?.total_routes ?? 0);
          setTotalFare(response?.payload?.total_fare ?? 0);
          setPeriod(response?.payload?.period ?? "");
          setIsFetching(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsFetching(false);
        setIsLoading(false);
        throw new Error("Error fetching data");
      }
    };

    fetchTableData();
  }, [
    pagination.pageSize,
    pagination.pageIndex,
    defaultDates,
    selectedStatus,
    busNumber,
    companyId,
    stationId,
    branchId,
    download,
    selectedFieldOfficer,
    tripCode,
    useTestCompanies,
    selectedPoint,
  ]);

  const columns = useMemo<MRT_ColumnDef<Trip>[]>(
    () => [
      {
        accessorFn: (row: any) =>
          `${row?.route?.from} - ${row?.route?.to}${
            row?.loading_point?.label ? ` (${row?.loading_point?.label})` : ""
          }`,
        id: "loading",
        header: "Loading Point",
      },
      {
        accessorFn: (row: any) => row?.bus?.reg_number,
        id: "bus",
        header: "Bus",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          row?.schedule?.status === "LOADED" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-blue-800 bg-blue-500 bg-opacity-[10%]">
              {row?.schedule?.status}
            </div>
          ) : row?.schedule?.status === "LOADING" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              {row?.schedule?.status}
            </div>
          ) : row?.schedule?.status === "SCALED" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-yellow-800 bg-yellow-500 bg-opacity-[10%]">
              {row?.schedule?.status}
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-gray-800 bg-gray-500 bg-opacity-[15%]">
              {row?.schedule?.status}
            </div>
          ),
        id: "status",
        header: "Status",
        size: 100,
      },
      {
        accessorFn: (row: any) => row?.schedule?.code,
        id: "code",
        header: "Trip Code",
      },
      {
        accessorFn: (row: any) => row?.branch?.name,
        id: "branch",
        header: "Branch",
      },
      {
        accessorFn: (row: any) => row?.bus?.capacity,
        id: "capacity",
        header: "Capacity",
        size: 80,
      },
      {
        accessorFn: (row: any) => row?.schedule?.onboard_passengers,
        id: "passengers",
        header: "Passengers",
        size: 80,
      },
      {
        accessorFn: (row: any) => row?.schedule?.onboard_minors,
        id: "minors",
        header: "Minors",
        size: 80,
      },
      {
        accessorFn: (row: any) =>
          row?.schedule?.created_at
            ? moment(row?.schedule?.created_at).format("Do MMM YYYY hh:mm a")
            : "",
        id: "created_at",
        header: "Scheduled On",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.schedule?.departures_at).format("Do MMM YYYY hh:mm a"),
        id: "departure",
        header: "Departure",
      },
      {
        accessorFn: (row: any) =>
          row?.schedule?.arrives_at
            ? moment(row?.schedule?.arrives_at).format("Do MMM YYYY hh:mm a")
            : "",
        id: "arrival",
        header: "Estimated Arrival",
      },

      {
        accessorFn: (row: any) => row?.driver?.name,
        id: "driver",
        header: "Driver",
        size: 140,
      },
      {
        accessorFn: (row: any) => row?.field_officer?.name,
        id: "field",
        header: "Field Officer",
        size: 140,
      },
      {
        accessorFn: (row: any) => row?.branch_supervisor?.name,
        id: "branch_supervisor",
        header: "Supervisor",
        size: 140,
      },
    ],
    // eslint-disable-next-line
    []
  );

  //export data
  const handleExportData = () => {
    setDownload(true);
  };

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4"
          }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                Trips {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-3">
              {/* passengers */}
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Passengers
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalPassengers ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              {/* minors */}
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Minors
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalMinors ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              {/* trips */}
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Trips
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalTrips ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              {/* capacities */}
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Capacities
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalCapacities ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Fare
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {parseFloat(totalFare.toString()).toLocaleString(
                        "en-GH",
                        {
                          style: "currency",
                          currency: "GHS",
                        }
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Routes
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalRoutes ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>

              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Branches
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalBranches ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>
              <div className="bg-white p-[17px] shadow-sm h-fit">
                {isFetching ? (
                  <CardShimmer />
                ) : (
                  <div className="">
                    <Typography variant="paragraph" className="font-semibold">
                      Loading Points
                    </Typography>
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-2xl"
                    >
                      {Intl.NumberFormat("en-EN").format(
                        Number(totalLoadingPoints ?? 0)
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 mt-5">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={tripsTableData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  enableClickToCopy={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  enableRowActions={true}
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <Menu>
                      <MenuItem
                        key={1}
                        onClick={() => {
                          window.open(
                            `${APP_URL}/busride/trips/${row?.original?.schedule?.id}/details`,
                            "_blank"
                          );
                          closeMenu();
                        }}
                        sx={{ m: 0 }}
                      >
                        <RemoveRedEyeOutlined sx={{ mr: 1 }} /> View
                      </MenuItem>
                      <MenuItem
                        key={1}
                        onClick={() => {
                          window.open(
                            `${APP_URL}/busride/trip/${row?.original?.schedule?.id}`,
                            "_blank"
                          );
                          closeMenu();
                        }}
                        sx={{ m: 0 }}
                      >
                        <DownloadOutlined sx={{ mr: 1 }} /> Download
                      </MenuItem>
                    </Menu>,
                  ]}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isFetching,
                    columnPinning: {
                      left: ["mrt-row-expand", "mrt-row-select"],
                      right: ["mrt-row-actions"],
                    },
                  }}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        p: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        color="primary"
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Download
                      </Button>
                    </Box>
                  )}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-100 overflow-y-auto pb-[4rem]`}
          >
            <AllTripsFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              status={selectedStatus}
              onStatuschange={(value) => setSelectedStatus(value)}
              busNum={busNumber}
              onBusNumChange={(e) => setBusNumber(e)}
              selectedBranch={selectedBranch}
              onBranchChange={(value) => onBranchSelect(value)}
              isLoadingPointActive={!selectedBranch}
              branchId={branchId}
              selectedPoint={selectedPoint}
              onPointChange={(value) => onPointSelect(value)}
              selectedFieldOfficer={selectedFieldOfficer}
              onFieldOfficerChange={(value) => onFieldOfficerSelect(value)}
              tripCodeInput={tripCode}
              onTripCodeInputChange={handleTripCodeInput}
              selectedCompany={selectedCompany}
              onCompanyChange={(value) => onCompanySelect(value)}
              useTestCompanies={useTestCompanies}
              handleCompanyTestChange={handleCompanyTestChange}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AllTripsList;
