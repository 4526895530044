import { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import BusRidesOverviewFilter from "../components/overviewFilter";
import {
  BuildingOffice2Icon,
  ChevronDoubleLeftIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import {
  PeopleAltOutlined,
  ChildCare,
  ReduceCapacity,
  AirportShuttle,
  CarCrashOutlined,
  LocationOn,
  ForkLeftOutlined,
  AccountTreeOutlined,
  LocationCityOutlined,
} from "@mui/icons-material";
import PageLayout from "../../../../components/layouts/pageLayout";
import { endOfDay, startOfDay } from "date-fns";
import { getApiWithMs } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";
import CardShimmer from "./trips/cardShimmer";
import { formatDateRange } from "../../../../helpers/utils";
// import LimitedTrips from './limitedTrips';
// import { useNavigate } from 'react-router-dom';
// import LimitedPassengers from './limitedPassengers';
// import LimitedTravellers from './limitedTravellers';

const BusRidesOverview = () => {
  // const navigate = useNavigate();
  const [hideFilter, setHideFilter] = useState<Boolean>(true);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branchId, setBranchId] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState("");
  const [stationId, setStationId] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [branches, setBranches] = useState<any>(null);
  const [companies, setCompanies] = useState<any>(null);
  const [loadingPoints, setLoadingPoints] = useState<number>(0);
  const [towns, setTowns] = useState<number>(0);
  const [midroutes, setMidroutes] = useState<number>(0);
  const [routes, setRoutes] = useState<number>(0);

  const [isFetchingCounts, setIsFetchingCounts] = useState(false);
  const [passengerCount, setPassengerCount] = useState<any>(null);
  const [tripsCount, setTripsCount] = useState<any>(null);
  const [minorsCount, setMinorsCount] = useState<any>(null);
  const [capacitiesCount, setCapacitiesCount] = useState<any>(null);
  const [migrations, setMigrations] = useState<number>(0);
  const [incidents, setIncidents] = useState<number>(0);

  const [selectedStatus, setSelectedStatus] = useState("LOADED");

  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setDefaultDates([startDate, endDate]);
    }
  };

  // handle onBranch select
  const onBranchSelect = (value: any) => {
    setSelectedBranch(value);
    setStationId(null);
    setBranchId(value.value);
  };
  // handle onPoint select
  const onPointSelect = (value: any) => {
    setSelectedPoint(value);
    setStationId(value.value);
  };

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const getBranches = async () => {
    try {
      setSubmitting(true);
      const res = await getApiWithMs(globalVariables.numOfBranches);
      setBranches(res?.payload);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  const getCompanies = async () => {
    try {
      setSubmitting(true);
      const res = await getApiWithMs(globalVariables.numOfCompanies);
      setCompanies(res?.payload);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  const getLoadingPoints = async () => {
    try {
      setSubmitting(true);
      const res = await getApiWithMs(globalVariables.numOfLoadingPoints);
      setLoadingPoints(res?.payload);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  const getRoutes = async () => {
    try {
      setSubmitting(true);
      const res = await getApiWithMs(globalVariables.numOfRoutes);
      setRoutes(res?.payload);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  const getMidroutes = async () => {
    try {
      setSubmitting(true);
      const res = await getApiWithMs(globalVariables.numOfMidroutes);
      setMidroutes(res?.payload);

      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  const getTowns = async () => {
    try {
      setSubmitting(true);
      const res = await getApiWithMs(globalVariables.numOfTowns);
      setTowns(res?.payload);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getBranches();
    getCompanies();
    getLoadingPoints();
    getRoutes();
    getMidroutes();
    getTowns();
  }, []);

  //fetch count
  useEffect(() => {
    const fetchCounts = async () => {
      setIsFetchingCounts(true);
      let defaultFilters: any = [];

      let filters: any = [];

      filters.push({
        f: "created_at",
        o: "between",
        p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
      });

      try {
        if (defaultDates) {
          if (branchId) {
            if (stationId) {
              defaultFilters.push(
                {
                  f: "departures_at",
                  o: "between",
                  p: [
                    defaultDates[0].toISOString(),
                    defaultDates[1].toISOString(),
                  ],
                },
                { f: "deleted_at", o: "is_null", p: [] },
                { f: "loading_point.id", o: "=", p: [stationId] },
                { f: "branch.id", o: "=", p: [branchId] },
                { f: "status", o: "=", p: [selectedStatus] }
              );
            } else {
              defaultFilters.push(
                {
                  f: "departures_at",
                  o: "between",
                  p: [
                    defaultDates[0].toISOString(),
                    defaultDates[1].toISOString(),
                  ],
                },
                { f: "deleted_at", o: "is_null", p: [] },
                { f: "branch.id", o: "=", p: [branchId] },
                { f: "status", o: "=", p: [selectedStatus] }
              );
            }
          } else {
            defaultFilters.push(
              {
                f: "departures_at",
                o: "between",
                p: [
                  defaultDates[0].toISOString(),
                  defaultDates[1].toISOString(),
                ],
              },
              { f: "status", o: "=", p: [selectedStatus] }
            );
          }
        }

        const met_response = await getApiWithMs(
          `${globalVariables.getTripsCountsRoute}?filters=${JSON.stringify(
            defaultFilters
          )}`
        );

        const migrationRes = await getApiWithMs(
          `${globalVariables.getMigrations}?filters=${JSON.stringify(filters)}`
        );

        setMigrations(migrationRes?.payload);

        const incidentRes = await getApiWithMs(
          `${globalVariables.getIncidents}?filters=${JSON.stringify(filters)}`
        );

        setIncidents(incidentRes.payload);

        //console.log(met_response);

        setTripsCount(met_response?.payload?.total_trips);
        setMinorsCount(met_response?.payload?.total_minors);
        setCapacitiesCount(met_response?.payload?.total_capacity);

        setPassengerCount(met_response?.payload?.total_manifests);
        setIsFetchingCounts(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCounts();
  }, [defaultDates, branchId, stationId, selectedStatus]);

  //tripDefaultFilters
  // const tripDefaultFilters = [
  //   {
  //     f: 'departures_at',
  //     o: 'between',
  //     p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
  //   },
  //   { f: 'deleted_at', o: 'is_null', p: [] },
  //   { f: 'status', o: '=', p: [selectedStatus] },
  // ];

  // const passengersDefaultFilters = [
  //   {
  //     f: 'created_at',
  //     o: 'between',
  //     p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
  //   },
  // ];

  // const travellersDefaultFilters = [
  //   {
  //     f: 'departures_at',
  //     o: 'between',
  //     p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
  //   },
  // ];

  return (
    <PageLayout>
      <div className="h-full bg-gray-200 overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4"
          } h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto pr-4 p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                Overview{" "}
                {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            {/* counts */}
            <div className="grid lg:grid-cols-3 gap-4 mt-3">
              {/* total pasengers */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {isFetchingCounts ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div>
                      <Typography variant="paragraph" className="font-semibold">
                        Passengers
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {Intl.NumberFormat("en-EN").format(passengerCount)}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <PeopleAltOutlined
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* total trips */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {isFetchingCounts ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Trips
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {Intl.NumberFormat("en-EN").format(tripsCount)}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <AirportShuttle
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* total minors */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {isFetchingCounts ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Minors
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {Intl.NumberFormat("en-EN").format(minorsCount)}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <ChildCare
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="grid lg:grid-cols-3 gap-4 mt-3">
              {/* total minors */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {isFetchingCounts ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Capacities
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {Intl.NumberFormat("en-EN").format(capacitiesCount)}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <ReduceCapacity
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* total migrations */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {isFetchingCounts ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Migrations
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {Intl.NumberFormat("en-EN").format(migrations)}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <FolderIcon className="w-10 h-10" />
                    </div>
                  </div>
                )}
              </div>
              {/* total incidents */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {isFetchingCounts ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Incidents
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {Intl.NumberFormat("en-EN").format(incidents)}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <CarCrashOutlined
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="sm:mt-4">
              <Typography variant="h4">Other</Typography>
            </div>
            <div className="grid lg:grid-cols-3 gap-4 mt-3">
              {/* total companies */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {submitting ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Companies
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {companies}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <BuildingOffice2Icon className="w-10 h-10" />
                    </div>
                  </div>
                )}
              </div>
              {/* total LP */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {submitting ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Loading Points
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {loadingPoints}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <LocationOn
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* total branches */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {submitting ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Branches
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {branches}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <AccountTreeOutlined
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="grid lg:grid-cols-3 gap-4 mt-3 mb-12">
              {/* total towns */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {submitting ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Towns
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {towns}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <LocationCityOutlined
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* total routes */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {submitting ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Routes
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {routes}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <ForkLeftOutlined
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* total midroutes */}
              <div className="p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0">
                {submitting ? (
                  <CardShimmer />
                ) : (
                  <div className="flex justify-between items-center">
                    <div className="">
                      <Typography variant="paragraph" className="font-semibold">
                        Midroutes
                      </Typography>
                      <Typography
                        variant="paragraph"
                        className="font-semibold text-2xl"
                      >
                        {midroutes}
                      </Typography>
                    </div>
                    <div className="text-oya-ghana-red">
                      <ForkLeftOutlined
                        sx={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div
              className={`${hideFilter
                  ? "lg:grid lg:grid-cols-2 gap-4"
                  : "lg:grid lg:grid-cols-1 gap-4"
                } mt-5 mb-20`}
            >
              <div className="bg-white shadow-sm p-5">
                <Typography variant="h6">Monthly - Capacity</Typography>
                <div className="h-full">
                  <MonthlyCapacity />
                </div>
              </div>
              <div className="bg-white shadow-sm p-5">
                <Typography variant="h6">Monthly - Passengers</Typography>
                <div className="h-full">
                  <MonthlyPassengers />
                </div>
              </div>
              <div className="bg-white shadow-sm p-5">
                <Typography variant="h6">Monthly - Trips</Typography>
                <div className="h-full">
                  <MonthlyTrips />
                </div>
              </div>
              <div className="bg-white shadow-sm p-5">
                <Typography variant="h6">Monthly - Users</Typography>
                <div className="h-full">
                  <MonthlyUsers />
                </div>
              </div>
            </div> */}

            {/* <div className='md:grid md:grid-cols-2 md:gap-x-5'>
              
              <div className='mt-5'>
                <div className='flex justify-between items-center'>
                  <Typography className='font-semibold text-xl mt-3'>
                    Trips
                  </Typography>
                  <button
                    onClick={() => navigate('/busride/trips')}
                    className='px-4 py-2 bg-transparent border border-oya-ghana-red text-oya-ghana-red mt-4 hover:bg-oya-ghana-red hover:text-white'
                  >
                    View All
                  </button>
                </div>
                <LimitedTrips defaultFilters={tripDefaultFilters} />
              </div>

              
              <div className='mt-5'>
                <div className='flex justify-between items-center'>
                  <Typography className='font-semibold text-xl mt-3'>
                    Passengers
                  </Typography>
                  <button
                    onClick={() => navigate('/busride/passengers')}
                    className='px-4 py-2 bg-transparent border border-oya-ghana-red text-oya-ghana-red mt-4 hover:bg-oya-ghana-red hover:text-white'
                  >
                    View All
                  </button>
                </div>
                <LimitedPassengers defaultFilters={passengersDefaultFilters} />
              </div>

              
              <div className='mt-0 mb-5'>
                <div className='flex justify-between items-center'>
                  <Typography className='font-semibold text-xl mt-3'>
                    Travellers
                  </Typography>
                  <button
                    onClick={() => navigate('/busride/travellers')}
                    className='px-4 py-2 bg-transparent border border-oya-ghana-red text-oya-ghana-red mt-4 hover:bg-oya-ghana-red hover:text-white'
                  >
                    View All
                  </button>
                </div>
                <LimitedTravellers defaultFilters={travellersDefaultFilters} />
              </div>
            </div> */}
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0"
            } h-full`}
          >
            <BusRidesOverviewFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              selectedBranch={selectedBranch}
              onBranchChange={(value) => onBranchSelect(value)}
              isLoadingPointActive={!selectedBranch}
              branchId={branchId}
              selectedPoint={selectedPoint}
              onPointChange={(value) => onPointSelect(value)}
              status={selectedStatus}
              onStatuschange={(value) => setSelectedStatus(value)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BusRidesOverview;
