const TotalHiringShimmer = () => {
  return (
    <div className="animate-pulse">
      <div className="flex items-center justify-between gap-10">
        <div>
          <div className="w-12 h-12 bg-gray-200 rounded-full" />
        </div>
        <div>
          <p className="font-normal text-transparent bg-gray-200 w-fit rounded">
            Total Hirings
          </p>
          <p className="font-semibold text-2xl text-transparent bg-gray-200 w-fit rounded">
            235
          </p>
        </div>
      </div>
    </div>
  );
};

export default TotalHiringShimmer;
