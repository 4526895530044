import { Fragment } from "react";
import MainServiceSelector from "../../components/general/mainServiceSelector";

const MainPage = () => {
  return (
    <Fragment>
      <div className="h-screen overflow-hidden bg-[#f5f5f5]">
        <div className="h-full overflow-auto">
          <div className="flex flex-col gap-3 justify-center items-center h-full">
            <p className="font-semibold lg:text-2xl text-xl">Select a Service</p>
            <div className="px-3 mt-3">
              <MainServiceSelector />
            </div>
            {/* <img className="w-[350px]" src={chooseService} alt="choose" /> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MainPage;
