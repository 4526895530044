import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./css/App.css";
import { ProSidebarProvider } from "react-pro-sidebar";
import MainPage from "./pages/main/main";
import ParcelOverView from "./pages/services/parcelService/screens/overview";
import ParcelList from "./pages/services/parcelService/screens/parcelList";
import HiringOverview from "./pages/services/hiringService/screens/overview";
import HiringList from "./pages/services/hiringService/screens/hiringList";
import BusRidesOverview from "./pages/services/busRides/screens/overview";
import AllTripsList from "./pages/services/busRides/screens/trips/tripList";
import AllPassengerList from "./pages/services/busRides/screens/passengers/passengerList";
import TravellersList from "./pages/services/busRides/screens/travellers/travellersList";
import AllBranchesList from "./pages/services/busRides/screens/branches/branchesList";
import PageNotFound from "./pages/not-found";
import ProtectedRoutes from "./routes/router";
import Reports from "./pages/services/busRides/screens/reports/reports";
import TripsManifets from "./pages/services/busRides/screens/trips/tripsManifets";
import GPRTUMembers from "./pages/services/busRides/screens/gprtuMembers/pgrtuMembers";
import PassengerStatement from "./pages/services/busRides/screens/statements/passengers/passengerStatement";
import VehicleStatement from "./pages/services/busRides/screens/statements/verhicles/vehicleStatement";
import DriverStatement from "./pages/services/busRides/screens/statements/drivers/driverStatement";
import { UserProvider } from "./context/userContext";
import BranchStatement from "./pages/services/busRides/screens/statements/branches/branchStatement";
import TripsManifestPDF from "./pages/services/busRides/screens/trips/tripsManifestPDF";
import FuelOverview from "./pages/services/fuelService/screens/overview";
import FuelCoupons from "./pages/services/fuelService/screens/coupons";
import FuelDiscounts from "./pages/services/fuelService/screens/discounts";
import FuelPayments from "./pages/services/fuelService/screens/payments";
import FuelSales from "./pages/services/fuelService/screens/sales";
import AllBookingsList from "./pages/services/busRides/screens/bookings/bookingsList";
import BusRidesPayments from "./pages/services/busRides/screens/payments/payments";
import MidrouteSales from "./pages/services/busRides/screens/midrouteSales";
import FuelTypes from "./pages/services/fuelService/screens/fuelTypes";
import FuelPrices from "./pages/services/fuelService/screens/fuelPrices";
import FuelBranches from "./pages/services/fuelService/screens/fuelBranches";
import FuelCompanies from "./pages/services/fuelService/screens/fuelCompanies";
import CouponDetails from "./pages/services/fuelService/screens/couponDetails";

const App = () => {
    return (
        <UserProvider>
            <ProSidebarProvider>
                <Router>
                    <Routes>
                        {/* 4040 route */}
                        <Route path="/*" element={<PageNotFound />} />

                        {/* default route */}
                        <Route
                            path="/"
                            element={
                                <ProtectedRoutes>
                                    <MainPage />
                                </ProtectedRoutes>
                            }
                        />

                        {/* parcel routes */}
                        <Route
                            path="/parcel/overview"
                            element={
                                <ProtectedRoutes>
                                    <ParcelOverView />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/parcel/allparcels"
                            element={
                                <ProtectedRoutes>
                                    <ParcelList />
                                </ProtectedRoutes>
                            }
                        />
                        {/* <Route
            path="/parcel/payments"
            element={
              <ProtectedRoutes>
                <ParcelPayments />
              </ProtectedRoutes>
            }
          /> */}

                        {/* hiring routes */}
                        <Route
                            path="/hiring/overview"
                            element={
                                <ProtectedRoutes>
                                    <HiringOverview />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/hiring/allhirings"
                            element={
                                <ProtectedRoutes>
                                    <HiringList />
                                </ProtectedRoutes>
                            }
                        />
                        {/* <Route
            path="/hiring/payments"
            element={
              <ProtectedRoutes>
                <HiringPayments />
              </ProtectedRoutes>
            }
          /> */}

                        {/* bus rides routes */}
                        <Route
                            path="/busride/overview"
                            element={
                                <ProtectedRoutes>
                                    <BusRidesOverview />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/payments"
                            element={
                                <ProtectedRoutes>
                                    <BusRidesPayments />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/trips"
                            element={
                                <ProtectedRoutes>
                                    <AllTripsList />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/trip/:id"
                            element={
                                <ProtectedRoutes>
                                    <TripsManifestPDF />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/trips/:schId/details"
                            element={
                                <ProtectedRoutes>
                                    <TripsManifets />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/passengers"
                            element={
                                <ProtectedRoutes>
                                    <AllPassengerList />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/travellers"
                            element={
                                <ProtectedRoutes>
                                    <TravellersList />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/reports"
                            element={
                                <ProtectedRoutes>
                                    <Reports />
                                </ProtectedRoutes>
                            }
                        />

                        <Route
                            path="/busride/midroute-sales"
                            element={
                                <ProtectedRoutes>
                                    <MidrouteSales />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/statements/branches"
                            element={
                                <ProtectedRoutes>
                                    <BranchStatement />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/statements/passengers"
                            element={
                                <ProtectedRoutes>
                                    <PassengerStatement />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/statements/vehicles"
                            element={
                                <ProtectedRoutes>
                                    <VehicleStatement />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/statements/drivers"
                            element={
                                <ProtectedRoutes>
                                    <DriverStatement />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/bookings"
                            element={
                                <ProtectedRoutes>
                                    <AllBookingsList />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/branches"
                            element={
                                <ProtectedRoutes>
                                    <AllBranchesList />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/busride/gprtu"
                            element={
                                <ProtectedRoutes>
                                    <GPRTUMembers />
                                </ProtectedRoutes>
                            }
                        />
                        {/* <Route
              path="/busride/incidents"
              element={
                <ProtectedRoutes>
                  <AllIncidentsList />
                </ProtectedRoutes>
              }
            /> */}

                        <Route
                            path="/fuel/overview"
                            element={
                                <ProtectedRoutes>
                                    <FuelOverview />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/fuel/coupons"
                            element={
                                <ProtectedRoutes>
                                    <FuelCoupons />
                                </ProtectedRoutes>
                            }
                        />

                        <Route
                            path="/fuel/coupons/:id/details"
                            element={
                                <ProtectedRoutes>
                                    <CouponDetails />
                                </ProtectedRoutes>
                            }
                        />

                        <Route
                            path="/fuel/discounts"
                            element={
                                <ProtectedRoutes>
                                    <FuelDiscounts />
                                </ProtectedRoutes>
                            }
                        />

                        <Route
                            path="/fuel/payments"
                            element={
                                <ProtectedRoutes>
                                    <FuelPayments />
                                </ProtectedRoutes>
                            }
                        />

                        <Route
                            path="/fuel/sales"
                            element={
                                <ProtectedRoutes>
                                    <FuelSales />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/fuel/prices"
                            element={
                                <ProtectedRoutes>
                                    <FuelPrices />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/fuel/types"
                            element={
                                <ProtectedRoutes>
                                    <FuelTypes />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/fuel/branches"
                            element={
                                <ProtectedRoutes>
                                    <FuelBranches />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/fuel/companies"
                            element={
                                <ProtectedRoutes>
                                    <FuelCompanies />
                                </ProtectedRoutes>
                            }
                        />
                        <Route
                            path="/hshs/overview"
                            element={
                                <ProtectedRoutes>
                                    <FuelOverview />
                                </ProtectedRoutes>
                            }
                        />
                    </Routes>
                </Router>
            </ProSidebarProvider>
        </UserProvider>
    );
};

export default App;
