import Select from "react-select";
import { useEffect, useState } from "react";
import { getApiWithMs } from "../../../../utils/api";

interface TownProps {
  selectedTown: any;
  onTownChange: (value: any) => void;
}

const TownFilterSelect = ({ selectedTown, onTownChange }: TownProps) => {
  const [allTowns, setAllTowns] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getApiWithMs(`/v2/towns`).then((response: any) => {
      const returnedData = response?.payload?.items;
      const formattedData = returnedData?.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setAllTowns(formattedData);
      setIsFetching(false);
    });
  }, []);

  return (
    <>
      <p>Filter by town</p>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select a town"
        isSearchable={true}
        name="towns"
        options={allTowns}
        isLoading={isFetching}
        value={selectedTown}
        onChange={(value) => onTownChange(value)}
        isClearable
      />
    </>
  );
};

export default TownFilterSelect;
