import Select from "react-select";
import { useState, useEffect } from "react";
import { getApiWithMs } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

interface loadingPointProps {
  selectedPoint: any;
  onPointChange: (value: any) => void;
  selectedBranch?: any;
}

const FieldOfficerSelect = ({
  selectedPoint,
  onPointChange,
  selectedBranch,
}: loadingPointProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [loadingPointList, setLoadingPointList] = useState([]);

  // fetch branches
  useEffect(() => {
    const fetchPoints = async () => {
      const filters = [
        {
          f: "account_type.code",
          o: "=",
          p: ["FIELD_OFFICER"],
        },
      ];
      if (selectedBranch) {
        filters.push({
          f: "branch.id",
          o: "=",
          p: [selectedBranch],
        });
      }
      setIsFetching(true);
      try {
        const response = await getApiWithMs(
          `${globalVariables.getStaff}?filters=${JSON.stringify(filters)}`
        );

        const mappedPoints = response?.payload?.items?.map(
          (points: any, i: number) => ({
            value: points?.user?.id,
            label: points?.user?.name,
          })
        );
        setLoadingPointList(mappedPoints);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
        throw new Error("Error fetching data");
      }
    };

    fetchPoints();
  }, [selectedBranch]);

  return (
    <>
      <p>Filter by Field Officer</p>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select a field officer"
        isSearchable={true}
        name="branches"
        options={loadingPointList}
        isDisabled={isFetching}
        value={selectedPoint}
        onChange={(value) => onPointChange(value)}
        isClearable
      />
    </>
  );
};

export default FieldOfficerSelect;
