import { Typography } from "@material-tailwind/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { globalVariables } from "../../../../../../helpers/globarVars";
import { getApiWithMs } from "../../../../../../utils/api";
import PageLayout from "../../../../../../components/layouts/pageLayout";

const BranchStatement = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [branchesData, setBrancgesData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  // handle download
  // const handleDownload = (theId: any) => {
  //   console.log(theId);
  //   try {
  //     const response = getApiWithMs(
  //       `${globalVariables.exportBranches}/${theId}/trips-export`
  //     );
  //     console.log(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const response = await getApiWithMs(
          `${globalVariables.getBranchesRoute}?sorting=created_at:desc`
        );
        console.log(response);
        setBrancgesData(response?.payload?.items);
        setRowCount(response?.payload?.total);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };
    fetchTableData();
  }, []);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.code,
        id: "code",
        header: "Code",
      },
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.bus_company?.name,
        id: "company",
        header: "Company",
      },
      {
        accessorFn: (row: any) => row?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.momo,
        id: "momo",
        header: "Momo",
      },
      {
        accessorFn: (row: any) => row?.location,
        id: "location",
        header: "Location",
      },
      {
        accessorFn: (row: any) => row?.region?.name,
        id: "region",
        header: "Region",
      },
      {
        accessorFn: (row: any) => row?.latitude + ", " + row?.longitude,
        id: "gps",
        header: "GPS",
      },
      // {
      //   accessorFn: (row: any) => (
      //     <button
      //       className="flex items-center gap-2 border-[1px] border-oya-ghana-red px-3 py-1 text-oya-ghana-red"
      //       onClick={() => handleDownload(row?.id)}
      //     >
      //       <CloudArrowDownIcon className="w-5 h-5" />
      //       Download
      //     </button>
      //   ),
      //   id: "statement",
      //   header: "Statement",
      // },
    ],
    []
  );

  return (
    <Fragment>
      <PageLayout>
        <div className="h-full p-[20px] bg-[#f5f5f5] overflow-hidden">
          <div className="h-full overflow-hidden">
            <div className="h-full overflow-y-auto pr-4 pb-20">
              <div className="flex gap-4 items-center mb-5">
                <Typography variant="h4">Statements / Branches</Typography>
              </div>

              <div className="mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={branchesData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  state={{
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default BranchStatement;
