import { Typography } from "@material-tailwind/react";
import OverviewFilterPane from "../components/overviewFilter";
import TotalHiringCard from "../components/totalHiring";
import PageLayout from "../../../../components/layouts/pageLayout";

const HiringOverview = () => {
  return (
    <PageLayout>
      <div className="h-full bg-[#f5f5f5] overflow-hidden">
        <div className="grid grid-cols-4 gap-4 h-full overflow-hidden">
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <Typography variant="h4">Overview</Typography>
            <div className="mt-3">
              <TotalHiringCard />
            </div>
          </div>

          <div className="cols-span-1">
            <OverviewFilterPane />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default HiringOverview;
