import { useState, useEffect } from 'react';
import { PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';
import TotalParcelShimmer from './totalHiringShimmer';

const TotalHiringCard = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <div className='p-5 bg-white shadow-lg rounded-[10px] mb-4 lg:mb-0 w-fit h-fit'>
      {isLoading ? (
        <TotalParcelShimmer />
      ) : (
        <div className='flex items-center justify-between gap-10'>
          <div>
            <Typography variant='paragraph' className='font-normal'>
              Total Parcels
            </Typography>
            <Typography variant='paragraph' className='font-semibold text-2xl'>
              235
            </Typography>
          </div>
          <div>
            <PresentationChartLineIcon className='w-10 h-10 text-oya-ghana-red' />
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalHiringCard;
