import Select from "react-select";
import { useState, useEffect } from "react";
import { getApiWithMs } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

interface loadingPointProps {
    isLoadingPointActive: any;
    branchId: any;
    selectedPoint: any;
    onPointChange: (value: any) => void;
}

const LodingPointSelect = ({
    isLoadingPointActive,
    branchId,
    selectedPoint,
    onPointChange,
}: loadingPointProps) => {
    const [isFetching, setIsFetching] = useState(false);
    const [loadingPointList, setLoadingPointList] = useState([]);

    // fetch branches
    useEffect(() => {
        const fetchPoints = async () => {
            setIsFetching(true);
            try {
                let url = `${globalVariables.getLoadingPoints}`;

                if (branchId) {
                    url = `${globalVariables.getBranchesRoute}/${branchId}/loading-points`;
                }
                const response = await getApiWithMs(url);
                const mappedPoints = response?.payload?.items?.map(
                    (points: any, i: number) => ({
                        value: points?.id,
                        label: `${points?.source?.name} - ${points?.destination?.name}`,
                    })
                );
                setLoadingPointList(mappedPoints);
                setIsFetching(false);
            } catch (error) {
                console.log(error);
                throw new Error("Error fetching data");
            }
        };

        fetchPoints();
    }, [branchId]);

    return (
        <>
            <p>Filter by loading point</p>
            <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a loading point"
                isSearchable={true}
                name="loading-point"
                options={loadingPointList}
                isDisabled={isLoadingPointActive || isFetching}
                value={selectedPoint}
                onChange={(value) => {
                    onPointChange(value);
                }}
                isClearable={true}
            />
        </>
    );
};

export default LodingPointSelect;
