import { Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import LodingPointSelect from "../../components/lodingPointFilter";
import BranchFilterSelect from "../../components/branchFilter";
import { DateRangePicker } from "rsuite";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { ReactNode } from "react";
import RouteSelect from "../../components/routeFilter";

interface filterBtnProps {
  onHide: () => void;
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  selectedBranch: any;
  onBranchChange: (value: any) => void;
  isLoadingPointActive: any;
  branchId: any;
  selectedLoadingPoint: any;
  onPointChange: (value: any) => void;

  isRouteActive: any;
  selectedRoute: any;
  onRouteChange: (value: any) => void;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;

  // Sets the position where the predefined range is displayed, the default is bottom.
  placement?: "bottom" | "left";
}

const TravellersFilter = ({
  onHide,
  defaultDates,
  handleDateChange,
  selectedBranch,
  onBranchChange,
  isLoadingPointActive,
  branchId,
  selectedLoadingPoint,
  onPointChange,
  isRouteActive,
  selectedRoute,
  onRouteChange,
}: filterBtnProps) => {
  const customRanges: Range[] = [
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: "This Week",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Last Week",
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: "This Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: "This Year",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: "Last Year",
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  return (
    <div className="px-3 py-[20px] border-l-[1px] border-gray-500 bg-white h-full overflow-y-auto">
      {/* header */}
      <div className="mb-2 flex justify-between items-center">
        <Typography variant="h5" color="blue-gray">
          Filter
        </Typography>
        <ChevronDoubleRightIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => onHide()}
        />
      </div>

      {/* content */}
      {/* date picker */}
      <div className="mt-5 w-full">
        <p>Date Range</p>
        <DateRangePicker
          className="w-full"
          placement="leftStart"
          value={defaultDates}
          onChange={(e: any) => handleDateChange(e)}
          ranges={customRanges}
        />
      </div>

      {/* <div className='mt-5 w-full'>
        <p>Travelers name</p>
        <TravelerNameFilterSelect onUserChange={(e) => onUserChange(e)} />
      </div> */}

      {/* branch select */}

      <div className="mt-5 w-full">
        <RouteSelect
          branchId={branchId}
          selectedRoute={selectedRoute}
          onRouteChange={(value) => onRouteChange(value)}
        />
      </div>
      <div className="mt-5 w-full">
        <BranchFilterSelect
          selectedBranch={selectedBranch}
          onBranchChange={(value) => onBranchChange(value)}
        />
      </div>

      {/* loading point select */}
      <div className="mt-5 w-full">
        <LodingPointSelect
          isLoadingPointActive={isLoadingPointActive}
          branchId={branchId}
          selectedPoint={selectedLoadingPoint}
          onPointChange={(value) => onPointChange(value)}
        />
      </div>

      <div className="flex justify-end mt-5 lg:hidden">
        <button
          className="border-[1px] border-oya-ghana-red text-oya-ghana-red px-4 py-1 rounded-[5px] hover:bg-oya-ghana-red hover:text-white"
          onClick={() => onHide()}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default TravellersFilter;
