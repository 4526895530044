import { Fragment } from "react";
import PageLayout from "../../../../../components/layouts/pageLayout";
import {
  DocumentDuplicateIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import ReportCardComponent from "./reportCardComponent";
import { useNavigate } from "react-router-dom";

const Reports = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <PageLayout>
        <div className="h-full bg-[#f5f5f5] overflow-y-auto px-[20px]">
          {/* overall report */}
          <p className="text-xl font-medium mt-[30px]">Overall Reports</p>
          <div className="lg:grid lg:grid-cols-3 mt-2 gap-5">
            <ReportCardComponent
              title="Trips"
              icon={
                <DocumentDuplicateIcon className="w-20 h-20 text-oya-ghana-red" />
              }
              onClick={() => {
                navigate("/busride/trips");
              }}
            />
            <ReportCardComponent
              title="Passengers"
              icon={<UserGroupIcon className="w-20 h-20 text-oya-ghana-red" />}
              onClick={() => {
                navigate("/busride/passengers");
              }}
            />
            <ReportCardComponent
              title="Travellers"
              icon={<UsersIcon className="w-20 h-20 text-oya-ghana-red" />}
              onClick={() => {
                navigate("/busride/travellers");
              }}
            />
          </div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default Reports;
