import CustomNavbar from "./navbar";
import CustomSideBar from "./sidebar";


const PageLayout = ({ children }: any) => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="flex h-full">
        {/* sidebar */}
        <CustomSideBar />
        {/* navbar */}
        <div className="w-full">
          <CustomNavbar />
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
