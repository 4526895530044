export const API_URL = process.env.REACT_APP_API_URL;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_MS_URL;
export const RIDE_MS_URL = process.env.REACT_APP_RIDE_MS_URL;
export const FUEL_BACKOFFICE_MS = process.env.REACT_APP_FUEL_BACKOFFICE_MS_URL;
export const FUEL_MS_URL = process.env.REACT_APP_FUEL_MS_URL;
export const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;
export const MAPS_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const PARCELS_URL = process.env.REACT_APP_PARCEL_MS_URL;
export const HIRINGS_URL = process.env.REACT_APP_HIRING_MS_URL;
export const OYA_PAYMENT_SERVICE_TOKEN =
  process.env.REACT_APP_OYA_PAYMENT_SERVICE_TOKEN;
export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL;
export const AUTH_MS_URL = process.env.REACT_APP_AUTHMS_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const ACCOUNTS_MS_URL = process.env.REACT_APP_ACCOUNTS_MS_URL;
