const CardShimmer = () => {
  return (
    <div className="animate-pulse">
      <div>
        <p className="font-normal text-transparent bg-gray-200 w-auto rounded">
          Passengers
        </p>
        <p className="font-normal text-transparent bg-gray-200 w-auto rounded mt-3">
          Passengers
        </p>
      </div>
    </div>
  );
};

export default CardShimmer;
