import Select from "react-select";
import { useEffect, useState } from "react";
import { getApiWithMs } from "../../../../utils/api";

interface regionProps {
  selectedRegion: any;
  onRegionChange: (value: any) => void;
}

const RegionSelect = ({ selectedRegion, onRegionChange }: regionProps) => {
  const [allRegions, setAllRegions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getApiWithMs(`/v2/regions`).then((response: any) => {
      const returnedData = response?.payload?.items;
      const formattedData = returnedData?.map((item: any) => ({
        value: item.name,
        label: item.name,
      }));
      setAllRegions(formattedData);
      setIsFetching(false);
    });
  }, []);

  return (
    <>
      <p>Filter by region</p>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select a region"
        isSearchable={true}
        name="regions"
        options={allRegions}
        isLoading={isFetching}
        value={selectedRegion}
        onChange={(value) => onRegionChange(value)}
      />
    </>
  );
};

export default RegionSelect;
