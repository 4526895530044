import Select from "react-select";
import { useState, useEffect } from "react";
import { getApiWithMs } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

interface chooseBranchProp {
  selectedBranch: any;
  onBranchChange: (value: any) => void;
  includeTestCompanies: boolean;
}

const CompanyFilterSelect = ({
  selectedBranch,
  onBranchChange,
  includeTestCompanies,
}: chooseBranchProp) => {
  const [isFetching, setIsFetching] = useState(false);
  const [branchesList, setBranchesList] = useState([]);
  const [branchNameInput, setBranchInput] = useState<any>("");

  const handleBranchChange = (value: any) => {
    onBranchChange(value || {});
  };

  const handleInputChange = (e: any) => {
    setBranchInput(e);
  };

  // fetch branches
  useEffect(() => {
    const fetchBranches = async () => {
      interface Filter {
        f: string;
        o: string;
        p: any[];
      }

      let defaultFilters: Filter[] = [];

      // Default filters
      defaultFilters.push({
        f: "name",
        o: "contains",
        p: [branchNameInput],
      });
      defaultFilters.push({
        f: "for_test",
        o: "=",
        p: [includeTestCompanies],
      });

      setIsFetching(true);

      try {
        const response = await getApiWithMs(
          `${
            globalVariables.getCompaniesRoute
          }?limit=500&filters=${JSON.stringify(defaultFilters)}`
        );
        const mappedBranches = response?.payload?.items.map(
          (branch: any, i: number) => ({
            value: branch?.id,
            label: branch?.name,
          })
        );
        setBranchesList(mappedBranches);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };

    fetchBranches();
  }, [branchNameInput, includeTestCompanies]);

  return (
    <>
      <p>Filter By Company</p>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select a Company"
        isSearchable={true}
        name="companies"
        options={branchesList}
        isLoading={isFetching}
        value={selectedBranch}
        onChange={handleBranchChange}
        onInputChange={handleInputChange}
        isClearable
      />
    </>
  );
};

export default CompanyFilterSelect;
