import React from "react";

interface ServiceContextProps {
  service: string;
  setService: React.Dispatch<React.SetStateAction<string>>;
}

const ServiceContext = React.createContext<ServiceContextProps>({
  service: "",
  setService: () => {},
});

export default ServiceContext;
