import { Typography } from "@material-tailwind/react";
import PageLayout from "../../../../components/layouts/pageLayout";
import StatsCard from "../components/statsCard";
import { useEffect, useState } from "react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { globalVariables } from "../../../../helpers/globarVars";
import { getFuelBackOfficeMSApi } from "../../../../utils/api";

const FuelOverview = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [paymentStatus] = useState("COMPLETED");

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountLeft, setTotalAmountLeft] = useState(0);
  const [totalAmountUsed, setTotalAmountUsed] = useState(0);

  const [totalNumber, setTotalNumber] = useState(0);

  const [defaultDates] = useState<any>([]);

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true);
      try {
        const defaultFilters = [];

        if (defaultDates.length !== 0) {
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (paymentStatus) {
          defaultFilters.push({
            f: "payment.status",
            o: "=",
            p: [paymentStatus],
          });
        }

        const [countResponse] = await Promise.all([
          await getFuelBackOfficeMSApi(
            `${
              globalVariables.getFuelCouponsCountsRoute
            }?sorting=created_at:desc&filters=${JSON.stringify(defaultFilters)}`
          ),
        ]);
        setTotalAmount(countResponse?.payload?.total_amount);
        setTotalAmountLeft(countResponse?.payload?.total_amount_left);
        setTotalAmountUsed(countResponse?.payload?.total_amount_used);
        setTotalNumber(countResponse?.payload?.total_number);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };

    fetchTableData();
  }, [defaultDates, paymentStatus]);

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div className=" h-screen overflow-hidden">
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Overview</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>
            <div className="lg:grid lg:grid-cols-4 mt-2 gap-4">
              <StatsCard
                label="No. of Coupons"
                value={totalNumber}
                loading={isLoading}
              />
              <StatsCard
                label="Amount"
                value={totalAmount}
                loading={isLoading}
                type="currency"
              />
              <StatsCard
                label="Amount Left"
                value={totalAmountLeft}
                loading={isLoading}
                type="currency"
              />
              <StatsCard
                label="Amount Used"
                value={totalAmountUsed}
                loading={isLoading}
                type="currency"
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelOverview;
