import { useMemo, useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import BookingsFilter from "./bookingsFilter";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { MaterialReactTable } from "material-react-table";
import { globalVariables } from "../../../../../helpers/globarVars";
import { getApiWithMs } from "../../../../../utils/api";
import moment from "moment";
import { formatDateRange } from "../../../../../helpers/utils";
import { endOfDay, startOfDay } from "date-fns";

const AllBookingsList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [defaultDates, setDefaultDates] = useState<any>("");

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [bookingData, setBookingData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        if (defaultDates) {
          const defaultFilters = [
            {
              f: "created_at",
              o: "between",
              p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
            },
          ];
          const response = await getApiWithMs(
            `${globalVariables.getBookingsRoute}?offset=${offset}&limit=${
              pagination.pageSize
            }&filters=${JSON.stringify(defaultFilters)}`
          );
          console.log(response);
          setBookingData(response?.payload?.items);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        } else {
          const response = await getApiWithMs(
            `${globalVariables.getBookingsRoute}?offset=${offset}&limit=${pagination.pageSize}&filters=[]`
          );
          console.log(response);
          setBookingData(response?.payload?.items);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };
    fetchTableData();
  }, [pagination.pageIndex, pagination.pageSize, defaultDates]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.user?.name,
        id: "userName",
        header: "User Name",
      },
      {
        accessorFn: (row: any) => row?.user?.phone,
        id: "userPhone",
        header: "User Phone",
      },
      {
        accessorFn: (row: any) => row?.message,
        id: "message",
        header: "Message",
      },
      {
        accessorFn: (row: any) =>
          row?.route?.from?.name + "-" + row?.route?.to?.name,
        id: "loadingPoint",
        header: "Loading Point",
      },
      {
        accessorFn: (row: any) => row?.minors,
        id: "minors",
        header: "Minors",
      },
      {
        accessorFn: (row: any) => row?.status?.name,
        id: "status",
        header: "Status",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.min_travel_time).format("Do MMM YYYY - hh:mm a") +
          "-" +
          moment(row?.max_travel_time).format("hh:mm a"),
        id: "date",
        header: "Date",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-auto">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                Advanced Bookings
                {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={bookingData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <BookingsFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AllBookingsList;
