import {
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  ListBulletIcon,
  NewspaperIcon,
  UsersIcon,
  TicketIcon,
  CurrencyDollarIcon,
  TagIcon,
  ShoppingBagIcon,
  UserGroupIcon,
  MapPinIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import MainPage from "../pages/main/main";
import PageNotFound from "../pages/not-found";
import {
  BusAlert,
  BusinessCenterOutlined,
  PriceChangeOutlined,
  Schedule,
  WaterDropOutlined,
} from "@mui/icons-material";

export const mainRouteList = [
  {
    path: "/",
    component: <MainPage />,
    exact: true,
  },
  {
    path: "*",
    component: <PageNotFound />,
  },
];

interface RoutingProps {
  title: string;
  path: string;
  icon: JSX.Element | null;
}

export const parcelRoutes: RoutingProps[] = [
  {
    title: "Overview",
    path: "/parcel/overview",
    icon: <ChartPieIcon className="w-5 h-5" />,
  },
  // {
  //   title: "Payments",
  //   path: "/parcel/payments",
  //   icon: <BanknotesIcon className="w-5 h-5" />,
  // },
  {
    title: "Parcels",
    path: "/parcel/allparcels",
    icon: <ListBulletIcon className="w-5 h-5" />,
  },
];

export const hiringRoutes: RoutingProps[] = [
  {
    title: "Overview",
    path: "/hiring/overview",
    icon: <ChartPieIcon className="w-5 h-5" />,
  },
  // {
  //   title: "Payments",
  //   path: "/hiring/payments",
  //   icon: <BanknotesIcon className="w-5 h-5" />,
  // },
  {
    title: "Hirings",
    path: "/hiring/allhirings",
    icon: <UsersIcon className="w-5 h-5" />,
  },
];

export const busRoutes: RoutingProps[] = [
  {
    title: "Overview",
    path: "/busride/overview",
    icon: <ChartPieIcon className="w-5 h-5" />,
  },
  {
    title: "Midroute Sales",
    path: "/busride/midroute-sales",
    icon: <MapPinIcon className="w-5 h-5" />,
  },
  {
    title: "Trips",
    path: "/busride/trips",
    icon: <ClipboardDocumentCheckIcon className="w-5 h-5" />,
  },
  {
    title: "Passenger Metrics",
    path: "/busride/passengers",
    icon: <UserGroupIcon className="w-5 h-5" />,
  },
  {
    title: "Traveller Metrics",
    path: "/busride/travellers",
    icon: <UsersIcon className="w-5 h-5" />,
  },
  {
    title: "Branch Statements",
    path: "/busride/statements/branches",
    icon: <NewspaperIcon className="w-5 h-5" />,
  },
  {
    title: "Passenger Statements",
    path: "/busride/statements/passengers",
    icon: <NewspaperIcon className="w-5 h-5" />,
  },
  {
    title: "Vehicle Statements",
    path: "/busride/statements/vehicles",
    icon: <BusAlert className="w-5 h-5" />,
  },
  {
    title: "Driver Statements",
    path: "/busride/statements/drivers",
    icon: <NewspaperIcon className="w-5 h-5" />,
  },
  {
    title: "Advanced Bookings",
    path: "/busride/bookings",
    icon: <Schedule className="w-5 h-5" />,
  },
  // {
  //   title: "Branches",
  //   path: "/busride/branches",
  //   icon: <BuildingOffice2Icon className="w-5 h-5" />,
  // },

  {
    title: "Payments",
    path: "/busride/payments",
    icon: <CurrencyDollarIcon className="w-5 h-5" />,
  },
  {
    title: "GPRTU Members",
    path: "/busride/gprtu",
    icon: <UsersIcon className="w-5 h-5" />,
  },
];

export const fuelRoutes: RoutingProps[] = [
  {
    title: "Overview",
    path: "/fuel/overview",
    icon: <ChartPieIcon className="w-5 h-5" />,
  },

  {
    title: "Coupons",
    path: "/fuel/coupons",
    icon: <TicketIcon className="w-5 h-5" />,
  },
  {
    title: "Payments",
    path: "/fuel/payments",
    icon: <CurrencyDollarIcon className="w-5 h-5" />,
  },
  {
    title: "Sales",
    path: "/fuel/sales",
    icon: <ShoppingBagIcon className="w-5 h-5" />,
  },
  {
    title: "Discounts",
    path: "/fuel/discounts",
    icon: <TagIcon className="w-5 h-5" />,
  },
  {
    title: "Fuel Prices",
    path: "/fuel/prices",
    icon: <PriceChangeOutlined className="w-5 h-5" />,
  },
  {
    title: "Fuel Types",
    path: "/fuel/types",
    icon: <WaterDropOutlined className="w-5 h-5" />,
  },
  {
    title: "Fuel Companies",
    path: "/fuel/companies",
    icon: <BusinessCenterOutlined className="w-5 h-5" />,
  },
  {
    title: "Fuel Branches",
    path: "/fuel/branches",
    icon: <BuildingOffice2Icon className="w-5 h-5" />,
  },
];
