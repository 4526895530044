import { useContext, useEffect } from "react";
import ServiceContext from "../../context/serviceContext";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../helpers/utils";
import { FaBusAlt } from "react-icons/fa";
import { LuPackageOpen, LuFuel } from "react-icons/lu";
// import { MdCarRental } from "react-icons/md";

const MainServiceSelector = () => {
  const navigate = useNavigate();
  const serviceContext = useContext(ServiceContext);

  //onload
  useEffect(() => {
    const currService = localStorage.getItem("selectedService");
    serviceContext.setService(currService || "");
    setCookie("selected_service", currService || "");
  }, [serviceContext]);

  //services list
  const servicesList = [
    {
      value: "bus",
      label: "Bus Ride",
      icon: <FaBusAlt className="h-10 w-10" />,
    },
    {
      value: "parcel",
      label: "Parcel Service",
      icon: <LuPackageOpen className="h-10 w-10" />,
    },
    // {
    //   value: "hiring",
    //   label: "Hiring Service",
    //   icon: <MdCarRental className="h-10 w-10" />,
    // },
    {
      value: "fuel",
      label: "Fuel Service",
      icon: <LuFuel className="h-10 w-10" />,
    },
    // {
    //   value: "hshs",
    //   label: "HSHS Service",
    //   icon: <LuGraduationCap className="h-10 w-10" />,
    // },
  ];

  // route on service change
  const routeToService = (selectedService: string) => {
    if (selectedService === "parcel") {
      navigate("/parcel/overview");
    } else if (selectedService === "hiring") {
      navigate("/hiring/overview");
    } else if (selectedService === "bus") {
      navigate("/busride/overview");
    } else if (selectedService === "fuel") {
      navigate("/fuel/overview");
    } else if (selectedService === "hshs") {
      navigate("/hshs/overview");
    } else {
      console.log("Choose a service");
      navigate("/");
    }
  };

  const handleSelectChange = (selectedOption: any) => {
    serviceContext.setService(selectedOption);
    localStorage.setItem("selectedService", selectedOption);
    setCookie("selected_service", selectedOption);
    routeToService(selectedOption);
  };

  return (
    <div className="h-full">
      <div className="lg:grid lg:grid-cols-3 lg:gap-[20px]">
        {servicesList.map((service, i) => (
          <div
            key={i}
            className="p-5 mb-4 lg:mb-0 rounded-[10px] bg-white shadow-md border-[1px] border-gray-300 w-full cursor-pointer hover:scale-110 duration-150 flex justify-center items-center hover:text-oya-ghana-red hover:border-oya-ghana-red"
            onClick={() => handleSelectChange(service?.value)}
          >
            <div>
              <div className="flex justify-center mb-5">{service?.icon}</div>
              <p className="text-center font-semibold">{service?.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainServiceSelector;
