import { useEffect, useState } from "react";
import { ACCOUNTS_URL } from "../helpers/constants";
import { getCookie } from "../helpers/utils";
import MainPage from "../pages/main/main";
import { Spinner } from "@material-tailwind/react";
import UnAuthorizedAccess from "../pages/unAuthorizedAccess";
import { getApiWithMs } from "../utils/api";
import { globalVariables } from "../helpers/globarVars";
import { notification } from "antd";

const ProtectedRoutes = ({ children }: any) => {

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [isChecking, setIsChecking] = useState(true)

  const token = getCookie("oya_token");
  const selectedService = getCookie("oya_selected_service");

  useEffect(() => {
    const getBranchRoles = async () => {
      setIsChecking(true)
      try {
        const allRoles = await getApiWithMs(globalVariables.get_branches);
        const assignedRoles = allRoles?.payload?.items;

        // Check if the user has an authorized role
        const authorizedRoles = ["DATA_OWNER", "DATA_EDITOR"];

        const hasAuthorizedRole = assignedRoles.map((user: any) => authorizedRoles.includes(user?.account_type?.code))
          .includes(true);

        if (hasAuthorizedRole) {
          console.log("Authorized user ");
          setIsChecking(false)
          setIsAuthorized(true);
        } else {
          console.log("Unauthorized user");
          setIsChecking(false)
          setIsAuthorized(false);
        }

      } catch (error) {
        console.log(error);
        notification.error({ message: "Error while fetching branch roles" });
      }
    }

    getBranchRoles()
    // eslint-disable-next-line
  }, [])


  if (token) {
    if (isChecking) {
      return <div className="flex justify-center items-center h-screen">
        <Spinner className="h-10 w-10" />
      </div>
    }
    else {
      if (isAuthorized) {
        if (selectedService) {
          return children;
        }
        else {
          return <MainPage />
        }
      }
      else {
        return <UnAuthorizedAccess />
      }
    }
  } else {
    return window.location.href = `${ACCOUNTS_URL}/login?appref=${window.location.href}`;
  }
};

export default ProtectedRoutes;
