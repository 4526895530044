import Select from "react-select";
import { useState, useEffect } from "react";
import { getApiWithMs } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

interface routeProps {
  isRouteActive?: any;
  branchId: any;
  selectedRoute: any;
  onRouteChange: (value: any) => void;
  selectedBranch?: any;
}

const RouteSelect = ({
  branchId,
  selectedRoute,
  onRouteChange,
  selectedBranch,
}: routeProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [routeList, setRouteList] = useState([]);
  const [routeInput, setRouteInput] = useState<any>("");
  const handleBranchChange = (value: any) => {
    onRouteChange(value || {});
  };

  const handleInputChange = (e: any) => {
    setRouteInput(e);
  };
  // fetch branches
  useEffect(() => {
    const fetchPoints = async () => {
      setIsFetching(true);
      try {
        const url = `${globalVariables.getRoutes}`;
        interface Filter {
          f: string;
          o: string;
          p: string[];
          c?: string;
        }

        let defaultFilters: Filter[] = [];
        // if (selectedBranch) {
        //   url = `${globalVariables.getBranchesRoute}/${selectedBranch}/routes`;
        // }
        defaultFilters.push(
          {
            f: "from.name",
            o: "contains",
            p: [`${routeInput}`],
            c: "OR",
          },
          {
            f: "to.name",
            o: "contains",
            p: [`${routeInput}`],
          }
        );

        const response = await getApiWithMs(
          `${url}?limit=400&filters=${JSON.stringify(defaultFilters)}`
        );
        const mappedPoints = response?.payload?.items?.map(
          (route: any, i: number) => ({
            value: route?.id,
            label: `${route?.from?.name} - ${route?.to?.name}`,
          })
        );
        setRouteList(mappedPoints);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };

    fetchPoints();
  }, [branchId, routeInput, selectedBranch]);

  return (
    <>
      <p>Filter by route</p>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select a route"
        isSearchable={true}
        isLoading={isFetching}
        name="route"
        options={routeList}
        value={selectedRoute}
        onChange={handleBranchChange}
        onInputChange={handleInputChange}
        isClearable
      />
    </>
  );
};

export default RouteSelect;
