import { Typography } from "@material-tailwind/react";
import CardShimmer from "../../busRides/components/cardShimmer";

interface StatsCardProps {
  loading: boolean;
  label: string;
  value: number;
  type?: "currency" | "normal";
}
const StatsCard = ({ loading, label, value, type }: StatsCardProps) => {
  return (
    <div className="bg-white p-[30px] shadow-sm h-fit">
      {loading ? (
        <CardShimmer />
      ) : (
        <div className="">
          <Typography variant="paragraph" className="font-semibold">
            {label}
          </Typography>
          <Typography variant="paragraph" className="font-semibold text-2xl">
            {type === "currency"
              ? parseFloat(value?.toString())?.toLocaleString("en-GH", {
                  style: "currency",
                  currency: "GHS",
                })
              : value}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default StatsCard;
