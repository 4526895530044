import { Drawer } from "antd";
import moment from "moment";
import { Button } from "rsuite";

interface PassengerTripsDrawerProps {
  handleDrawerClose: () => void;
  isOpened: boolean;
  isLoading: boolean;
  trips: any[];
  total: number;
  entityId: string;
  handlePagination: (entityId: string) => void;
}

const PassengerTripsDrawer = ({
  handleDrawerClose,
  isOpened,
  isLoading,
  trips,
  total,
  entityId,
  handlePagination,
}: PassengerTripsDrawerProps) => {
  //console.log(trips);

  return (
    <Drawer
      title="Passenger Trips"
      placement="right"
      onClose={handleDrawerClose}
      open={isOpened}
      zIndex={100}
      size="default"
      destroyOnClose={true}
      maskClosable={false}
    >
      <div className="h-full overflow-y-auto w-full">
        {isLoading ? (
          <div className="w-full animate-pulse">
            <div className="w-full h-[40px] mb-2 bg-gray-300" />
            <div className="w-full h-[40px] mb-2 bg-gray-300" />
            <div className="w-full h-[40px] mb-2 bg-gray-300" />
            <div className="w-full h-[40px] mb-2 bg-gray-300" />
            <div className="w-full h-[40px] mb-2 bg-gray-300" />
          </div>
        ) : (
          <>
            {trips.length > 0 ? (
              <>
                {trips.map((item, index) => (
                  <div
                    className="w-full py-3 border-b border-gray-200"
                    key={index}
                  >
                    <div className="font-medium">
                      Passenger: {item?.user?.name}
                    </div>
                    <div className="flex items-center mt-2 justify-between">
                      <div>
                        <div className="font-medium">
                          {item?.schedule?.loading_point}
                        </div>
                        <div className="font-medium">
                          {item?.schedule?.branch}
                        </div>
                      </div>
                      <div>
                        <div>{item?.schedule?.bus}</div>
                        <div>Seat No: {item?.schedule?.seat_no}</div>
                      </div>
                    </div>
                    <div className="mt-2 text-gray-600 text-sm flex items-center ">
                      <div>Field Officer: </div>
                      <div>{item?.field_officer?.name}</div>
                    </div>
                    <div className="text-gray-600 text-sm">
                      {moment(item?.created_at).format("MMMM Do, YYYY")}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="text-center text-gray-600">
                Passenger has no recorded trips
              </div>
            )}
          </>
        )}
        {total > 10 && (
          <div className="w-full flex items-center justify-center mt-8">
            <Button
              onClick={() => handlePagination(entityId)}
              type="button"
              appearance="primary"
              color="blue"
            >
              Load more
            </Button>
          </div>
        )}
        <br />
        <br />
        <br />
        <br />
      </div>
    </Drawer>
  );
};
export default PassengerTripsDrawer;
