export const globalVariables = {
  getBookingsRoute: "/v2/advanced-bookings",
  getPaymentsRoute: "/v2/payments",
  getPaymentsCountsRoute: "/v2/payments-counts",
  transactionsDetailsRoute: "/api/v2/transactions",
  getTripsRoute: "/v2/trips",
  exportTrips: "/v2/trips-export",
  getStaff: "/v2/staff",
  getTripsCountsRoute: "/v2/trips-counts",
  getTravellersRoute: "/v2/metrics/travellers",
  getBranchesRoute: "/v2/branches",
  getRoutes: "/v2/routes",
  getCompaniesRoute: "/v2/companies",
  getLoadingPoints: "/v2/loading-points",
  getPassengersRoute: "/v2/passengers",
  downloadPassengersRoute: "/v2/download-passengers",
  getPassengersCountsRoute: "/v2/passengers-counts",
  getSchedules: "/v2/schedules",
  metricsCounts: "/oya/metrics/counts",
  usersCount: "/oya/users/counts",
  numOfRoutes: "/v2/statistics/number-of-routes",
  numOfMidroutes: "/v2/statistics/number-of-midroutes",
  numOfLoadingPoints: "/v2/statistics/number-of-loading-points",
  numOfCompanies: "/v2/statistics/number-of-bus-companies",
  numOfTowns: "/v2/statistics/number-of-towns",
  numOfBranches: "/v2/statistics/number-of-branches",
  getMigrations: "/v2/statistics/number-of-bus-migrations",
  getIncidents: "/v2/statistics/number-of-incidents",
  passengerStatement: "/manifests",
  vehiclesStatement: "/v2/buses",
  driverStatement: "/oya/drivers",
  exportBranches: "/reports/stations",
  exportVehicles: "/reports/vehicles",
  exportDrivers: "/reports/drivers",
  passengerCapaicties: "/oya/metrics/passenger_capacity_series",
  passengerSeries: "/oya/metrics/passengers_series",
  tripSeries: "/oya/metrics/trips_series",
  usersSeries: "/oya/metrics/users_series",
  incidentsRoute: "/v2/accidents",
  parcelsRoute: "/packages",
  hiringsRoute: "/hiring_request/all",
  get_gprtu_members: "/v2/gprtu-members",
  get_coupon_detail: `/v1/coupons`,
  get_users: "/v2/accounts",
  get_branches: "/v2/me/branch-roles",
  exportTravellers: "/v2/metrics/travellers-export",
  getFuelCoupons: "/v1/coupons",
  downloadFuelCoupons: "/coupons",
  getFuelTypes: "/v1/fuel-types",
  getFuelPrices: "/v1/fuel-prices",
  getFuelSales: "/v1/sales",
  getFuelSalesCountsRoute: "/v1/sales-counts",
  downloadFuelSales: "/v1/download-sales",
  getFuelBranches: "/v1/branches",
  getFuelCompanies: "/v1/companies",
  downloadFuelDiscounts: "/v1/download-discounts",
  getFuelDiscounts: "/v1/discounts",
  getFuelPayments: "/v1/transactions",
  getFuelCouponsCountsRoute: "/v1/coupons-counts",
};
