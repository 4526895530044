import { useEffect, useState } from "react";
import {
  Font,
  Page,
  View,
  Text,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { Passenger, Schedule } from "./types";
import { getApiWithMs } from "../../../../../utils/api";
import { globalVariables } from "../../../../../helpers/globarVars";
import moment from "moment";

const ManifestPDF = ({
  details,
  passengers,
}: {
  details: Schedule | undefined;
  passengers: Passenger[];
}) => {
  //console.log({ d: details, p: passengers });
  return (
    <Document>
      <Page style={styles.body} orientation="landscape">
        {/* <Text style={styles.header} fixed>
        ~ Created with react-pdf ~
      </Text> */}
        <Text style={styles.title}>Trip Manifest</Text>
        <Text style={styles.author}>Trip CODE: {details?.code}</Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <Text style={styles.titleText}>Route</Text>
            <Text style={styles.text}>{passengers.length} Passenger(s)</Text>
            <Text style={styles.text}>
              {details?.route?.from?.name} - {details?.route?.to?.name}
            </Text>
          </View>
          <View>
            <Text style={styles.titleText}>{details?.bus?.reg_number}</Text>
            <Text style={styles.text}>
              Driver Name: {details?.driver?.name}
            </Text>
            <Text style={styles.text}>Phone: {details?.driver?.phone}</Text>
          </View>
          <View>
            <Text style={styles.titleText}>Departure/Arrival</Text>
            <Text style={styles.text}>
              Departure: {moment(details?.departures_at).format("LLL")}
            </Text>
            <Text style={styles.text}>
              Estimated Arrival: {moment(details?.arrives_at).format("LLL")}
            </Text>
          </View>
          <View>
            <Text style={styles.titleText}>Payments</Text>
            <Text style={styles.text}>Payments:</Text>
            <Text style={styles.text}>Cash: </Text>
          </View>
        </View>

        {/* Passengers */}
        <Text style={{ marginTop: "15px" }}>Passenger(s)</Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <View style={{ width: "100px" }}>
            <Text style={styles.textSub}>Passenger Name</Text>
          </View>
          <View style={{ width: "110px" }}>
            <Text style={styles.textSub}>Phone Number</Text>
          </View>
          <View style={{ width: "110px" }}>
            <Text style={styles.textSub}>Primay Emergency Contact</Text>
          </View>
          <View style={{ width: "70px" }}>
            <Text style={styles.textSub}>Minors</Text>
          </View>
          <View style={{ width: "90px" }}>
            <Text style={styles.textSub}>Payment Method</Text>
          </View>
          <View style={{ width: "70px" }}>
            <Text style={styles.textSub}>Seat No.</Text>
          </View>
          <View style={{ width: "90px" }}>
            <Text style={styles.textSub}>Enrolled On</Text>
          </View>
        </View>
        <View>
          {passengers?.map((item, index) => (
            <View
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                paddingVertical: "10px",
                borderBottomWidth: "1px",
                borderBottomColor: "#ccc",
              }}
            >
              <View style={{ width: "100px" }}>
                {/* <Text style={styles.text}>Passenger Name</Text> */}
                <Text style={styles.text}>{item?.name}</Text>
              </View>
              <View style={{ width: "110px" }}>
                {/* <Text style={styles.text}>Phone Number</Text> */}
                <Text style={styles.text}>{item?.phone_number}</Text>
              </View>
              <View style={{ width: "110px" }}>
                {/* <Text style={styles.text}>Primay Emergency Contact</Text> */}
                <Text style={styles.text}>{item?.ice_contact}</Text>
              </View>
              <View
                style={{
                  width: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Text style={styles.text}>Minors</Text> */}
                <Text style={styles.text}>{item?.minors}</Text>
              </View>
              <View style={{ width: "90px" }}>
                {/* <Text style={styles.text}>Payment Method</Text> */}
                <Text style={styles.text}>{item?.payment_method}</Text>
              </View>
              <View style={{ width: "70px" }}>
                {/* <Text style={styles.text}>Seat No.</Text> */}
                <Text style={styles.text}>{item?.seat?.number}</Text>
              </View>
              <View style={{ width: "90px" }}>
                {/* <Text style={styles.text}>Enrolled On</Text> */}
                <Text style={styles.text}>
                  {moment(item?.created_at).format("MM/DD/YYYY")}
                </Text>
              </View>
            </View>
          ))}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const TripsManifestPDF = () => {
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const [details, setDetails] = useState<Schedule>();
  const [passengersTableData, setPassengersTableData] = useState([]);
  const [isFetchingPassengers, setIsFetchingPassengers] = useState(false);

  const { id } = useParams();
  console.log(id);
  const fetchDetails = async () => {
    setIsFetchingDetails(true);
    try {
      const response = await getApiWithMs(
        `${globalVariables.getSchedules}/${id}/details`
      );

      //console.log(response);
      setDetails(response?.payload);

      setIsFetchingDetails(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPassengers = async () => {
    setIsFetchingPassengers(true);
    try {
      const response = await getApiWithMs(
        `${globalVariables.getSchedules}/${id}/passengers`
      );

      //console.log(response);
      setPassengersTableData(response?.payload?.items);
      setIsFetchingPassengers(false);
    } catch (error) {
      console.log(error);
    }
  };

  //fetch details
  useEffect(() => {
    fetchDetails();
    fetchPassengers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isFetchingDetails || isFetchingPassengers) {
    return <div className="flex justify-center items-center">Loading...</div>;
  }
  return (
    <div>
      <div>
        <PDFViewer height={"800px"} width={"100%"}>
          <ManifestPDF details={details} passengers={passengersTableData} />
        </PDFViewer>
      </div>
      <div className="flex justify-center items-center h-10 w-full p-5">
        <button className="bg-[#7c0000] hover:bg-red-700 py-3 px-5 mt-5 text-white flex justify-center items-center ">
          <PDFDownloadLink
            document={
              <ManifestPDF details={details} passengers={passengersTableData} />
            }
            fileName={`trip-${id}-manifest.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <div className="text-white">Download File</div>
              )
            }
          </PDFDownloadLink>
        </button>
      </div>
    </div>
  );
};

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  textSub: {
    fontSize: 10,
    color: "grey",
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  titleText: {
    fontSize: 13,
    paddingBottom: "2px",
    marginBottom: "2px",
    textAlign: "justify",
    fontFamily: "Times-Roman",
    borderBottomWidth: "1px",
    borderBottomColor: "#242424",
  },
  text: {
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default TripsManifestPDF;
