import { Typography, Button } from "@material-tailwind/react";
import PageLayout from "../../../../components/layouts/pageLayout";
import { useState, useEffect, useMemo } from "react";
import {
  ChevronDoubleLeftIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import {
  getFuelBackOfficeMSApi,
  fuelBackofficeDownloadApi,
} from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";
import { MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import SalesFilter from "./salesFilter";

// import { formatPhoneNumber2 } from "../../../../utils/functions";
import moment from "moment";
import StatsCard from "../components/statsCard";
const FuelCoupons = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  //   const [thePhoneNum, setThePhoneNum] = useState("");
  //   const [actualPhone, setActualPhone] = useState("");

  const [name, setName] = useState("");
  //   const [reference, setReference] = useState("");
  //   const [status, setStatus] = useState("");
  const [branchInput, setBranch] = useState(null);
  const [branchId, setBranchId] = useState("");
  const [, setStatsPeriod] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesAmount, setTotalSalesAmount] = useState(0);

  const [couponSerialNumber, setCouponSerialNumberInput] = useState("");

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  //   const handlePhoneInput = (e: any) => {
  //     setThePhoneNum(e.target.value);
  //     if (thePhoneNum.length > 3) {
  //       setActualPhone(e.target.value);
  //     }
  //   };

  const handleNameInput = (e: any) => {
    setName(e.target.value);
  };

  const handleBranchInput = (e: any) => {
    setBranch(e);
    setBranchId(e?.value);
  };

  const handleCouponSerialNumberInput = (e: any) => {
    setCouponSerialNumberInput(e.target.value);
  };

  //   const handleReferenceInput = (e: any) => {
  //     setReference(e.target.value);
  //   };

  //   const handleStatusInput = (value: any) => {
  //     setStatus(value);
  //   };

  //   const handlePaymentStatusInput = (value: any) => {
  //     setPaymentStatus(value);
  //   };

  // fetch data
  const fetchTableData = async (download = false) => {
    setIsFetching(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (defaultDates.length !== 0) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      // if (actualPhone) {
      //   defaultFilters.push({
      //     f: "owner.phone",
      //     o: "contains",
      //     p: [formatPhoneNumber2(actualPhone)],
      //   });
      // }

      if (name) {
        defaultFilters.push({
          g: [
            {
              f: "fuel_attendant.name",
              o: "contains",
              p: [name],
              c: "OR",
            },
            {
              f: "fuel_attendant.phone",
              o: "contains",
              p: [name],
            },
          ],
        });
      }

      if (couponSerialNumber) {
        defaultFilters.push({
          f: "coupon.reference",
          o: "contains",
          p: [couponSerialNumber],
        });
      }
      if (branchId) {
        defaultFilters.push({
          f: "branch.id",
          o: "=",
          p: [branchId],
        });
      }

      // if (reference) {
      //   defaultFilters.push({
      //     f: "reference",
      //     o: "=",
      //     p: [reference],
      //   });
      // }

      // if (status) {
      //   defaultFilters.push({
      //     f: "status",
      //     o: "=",
      //     p: [status],
      //   });
      // }

      // if (paymentStatus) {
      //   defaultFilters.push({
      //     f: "payment.status",
      //     o: "=",
      //     p: [paymentStatus],
      //   });
      // }

      if (download) {
        const response = await fuelBackofficeDownloadApi(
          `${globalVariables.downloadFuelSales}?limit=${
            pagination.pageSize
          }&sorting=created_at:desc&offset=${offset}&filters=${JSON.stringify(
            defaultFilters
          )}`
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "sales-data.xlsx");
        document.body.appendChild(link);
        link.click();
      } else {
        const [listResponse, countResponse] = await Promise.all([
          await getFuelBackOfficeMSApi(
            `${globalVariables.getFuelSales}?limit=${
              pagination.pageSize
            }&sorting=created_at:desc&offset=${offset}&filters=${JSON.stringify(
              defaultFilters
            )}`
          ),
          await getFuelBackOfficeMSApi(
            `${
              globalVariables.getFuelSalesCountsRoute
            }?filters=${JSON.stringify(defaultFilters)}`
          ),
        ]);

        setData(listResponse?.payload?.items || []);
        setRowCount(listResponse?.payload?.total);

        setStatsPeriod(countResponse?.payload?.period);
        setTotalSales(countResponse?.payload?.total_sales);
        setTotalSalesAmount(countResponse?.payload?.total_sales_amount);

        setIsFetching(false);
        setIsLoading(false);
      }
      //console.log(response);
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchTableData();

    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    name,
    couponSerialNumber,
    branchId,
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) =>
          `${row?.fuel_attendant?.first_name} ${row?.fuel_attendant?.last_name}`,
        id: "fuel_attendant_name",
        header: "Fuel Attendant",
      },
      {
        accessorFn: (row: any) => row?.fuel_attendant?.phone,
        id: "fuel_attendant_phone",
        header: "Attendant Phone",
      },
      {
        accessorFn: (row: any) => row?.company?.name,
        id: "company",
        header: "Company",
      },
      {
        accessorFn: (row: any) => row?.branch?.name,
        id: "branch",
        header: "Branch",
      },
      {
        accessorFn: (row: any) =>
          `${row?.amount_sold_currency} ${row?.amount_sold}`,
        id: "amount_sold",
        header: "Amount Sold",
      },
      {
        accessorFn: (row: any) =>
          `${row?.amount_sold_currency} ${row?.coupon_amount_left}`,
        id: "coupon_amount_left",
        header: "Amount Left",
      },
      {
        accessorFn: (row: any) => `${row?.owner?.name}`,
        id: "coupon_owner",
        header: "Coupon Owner",
      },
      {
        accessorFn: (row: any) => `${row?.coupon?.reference}`,
        id: "coupon_reference",
        header: "Coupon Serial No.",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "sale_date",
        header: "Sale Date",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Sales</Typography>

              <div className="flex items-center gap-5">
                <Button
                  onClick={() => {
                    fetchTableData(true);
                  }}
                  variant="gradient"
                  color="red"
                  size="sm"
                  className="flex items-center justify-center"
                >
                  Download <CloudArrowDownIcon className="h-5 w-5 ml-2" />
                </Button>
                <ChevronDoubleLeftIcon
                  className={`${
                    hideFilter ? "block" : "block lg:hidden"
                  } h-5 w-5 cursor-pointer`}
                  onClick={() => handleFilterHide()}
                />
              </div>
            </div>

            <div className="lg:grid lg:grid-cols-4 mt-2 gap-5">
              <StatsCard
                label="No. of Sales"
                value={totalSales}
                loading={isLoading}
              />
              <StatsCard
                label="Amount"
                value={totalSalesAmount}
                loading={isLoading}
                type="currency"
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={data}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <SalesFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              nameInput={name}
              onNameInputChange={handleNameInput}
              serialInput={couponSerialNumber}
              onSerialInputChange={handleCouponSerialNumberInput}
              selectedBranch={branchInput}
              onBranchInputChange={handleBranchInput}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelCoupons;
