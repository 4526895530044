import { useEffect, useMemo, useState } from "react";
import { Typography } from "@material-tailwind/react";
import BranchesFilter from "./branchesFilter";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { getApiWithMs } from "../../../../../utils/api";
import { globalVariables } from "../../../../../helpers/globarVars";
import MaterialReactTable from "material-react-table";
import { formatDateRange } from "../../../../../helpers/utils";
import { endOfDay, startOfDay } from "date-fns";

const AllBranchesList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [branchesData, setBrancgesData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>("");

  const [branchName, setBranchName] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleNameChange = (e: any) => {
    console.log(e.target.value);
    setBranchName(e.target.value);
  };

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        if (defaultDates) {
          const response = await getApiWithMs(
            `${
              globalVariables.getBranchesRoute
            }?sorting=created_at:desc&limit=${
              pagination.pageSize
            }&page=${offset}&start_date=${JSON.stringify(
              defaultDates[0].toISOString()
            )}&end_date=${JSON.stringify(defaultDates[1].toISOString())}`
          );
          console.log(response);
          setBrancgesData(response?.payload?.items);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        } else {
          const response = await getApiWithMs(
            `${globalVariables.getBranchesRoute}?sorting=created_at:desc&limit=${pagination.pageSize}&page=${offset}`
          );
          console.log(response);
          setBrancgesData(response?.payload?.items);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        }

        if (branchName) {
          const response = await getApiWithMs(
            `${globalVariables.getBranchesRoute}?sorting=created_at:desc&limit=${pagination.pageSize}&page=${offset}&name=${branchName}`
          );
          console.log(response);
          setBrancgesData(response?.payload?.items);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };
    fetchTableData();
  }, [defaultDates, pagination.pageIndex, pagination.pageSize, branchName]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.code,
        id: "code",
        header: "Code",
      },
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.bus_company?.name,
        id: "company",
        header: "Company",
      },
      {
        accessorFn: (row: any) => row?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.momo,
        id: "momo",
        header: "Momo",
      },
      {
        accessorFn: (row: any) => row?.location,
        id: "location",
        header: "Location",
      },
      {
        accessorFn: (row: any) => row?.region?.name,
        id: "region",
        header: "Region",
      },
      {
        accessorFn: (row: any) => row?.latitude + ", " + row?.longitude,
        id: "gps",
        header: "GPS",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                Branches{" "}
                {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={branchesData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  state={{
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <BranchesFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              branchName={branchName}
              onBranchNameChange={(e) => handleNameChange(e)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AllBranchesList;
