import { Typography } from "@material-tailwind/react";
import PageLayout from "../../../../components/layouts/pageLayout";
import { useEffect, useMemo, useState } from "react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { getFuelBackOfficeMSApi } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";
import moment from "moment";
import MaterialReactTable from "material-react-table";

const FuelPrices = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(true);
  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const [listResponse] = await Promise.all([
        await getFuelBackOfficeMSApi(
          `${globalVariables.getFuelPrices}?limit=${pagination.pageSize}&offset=${offset}&sorting=created_at:desc`
        ),
      ]);
      setData(listResponse?.payload?.items || []);
      setRowCount(listResponse?.payload?.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "created_at",
        header: "Date Added",
        size: 200,
      },
      {
        accessorFn: (row: any) => row?.fuel_type?.name,
        id: "fuel_type",
        header: "Fuel Type",
        size: 100,
      },
      {
        accessorFn: (row: any) => `${row?.currency}${row?.unit_price}`,
        id: "unit_price",
        header: "Unit Price",
        size: 100,
      },
      {
        accessorFn: (row: any) =>
          `${row?.currency}${Number(row?.unit_discount).toFixed(2)}`,
        id: "unit_discount",
        header: "Unit Discounts",
        size: 100,
      },
    ],
    []
  );
  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div className="h-screen overflow-hidden">
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Fuel Prices</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>
            <div className="lg:grid lg:grid-cols-4 mt-2 gap-5"></div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={data}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isLoading,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelPrices;
