import { Typography } from "@material-tailwind/react";
import PageLayout from "../../../../components/layouts/pageLayout";
import { useState, useEffect, useMemo } from "react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { getFuelBackOfficeMSApi } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";
import { MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import CouponsFilter from "./couponsFilter";
import { formatPhoneNumber2 } from "../../../../utils/functions";
import moment from "moment";

const FuelCoupons = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [thePhoneNum, setThePhoneNum] = useState("");
  const [actualPhone, setActualPhone] = useState("");

  const [name, setName] = useState("");
  const [reference, setReference] = useState("");
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const handlePhoneInput = (e: any) => {
    setThePhoneNum(e.target.value);
    if (thePhoneNum.length > 3) {
      setActualPhone(e.target.value);
    }
  };

  const handleNameInput = (e: any) => {
    setName(e.target.value);
  };

  const handleReferenceInput = (e: any) => {
    setReference(e.target.value);
  };

  const handleStatusInput = (value: any) => {
    setStatus(value);
  };

  const handlePaymentStatusInput = (value: any) => {
    setPaymentStatus(value);
  };

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (defaultDates.length !== 0) {
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (actualPhone) {
          defaultFilters.push({
            f: "owner.phone",
            o: "contains",
            p: [formatPhoneNumber2(actualPhone)],
          });
        }

        if (name) {
          defaultFilters.push({
            f: "owner.name",
            o: "contains",
            p: [name],
          });
        }

        if (reference) {
          defaultFilters.push({
            f: "reference",
            o: "=",
            p: [reference],
          });
        }

        if (status) {
          defaultFilters.push({
            f: "status",
            o: "=",
            p: [status],
          });
        }

        if (paymentStatus) {
          defaultFilters.push({
            f: "payment.status",
            o: "=",
            p: [paymentStatus],
          });
        }

        const response = await getFuelBackOfficeMSApi(
          `${globalVariables.getFuelPayments}?sorting=created_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        console.log(response);
        setData(response?.payload?.items || []);
        setRowCount(response?.payload?.total);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };

    fetchTableData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualPhone,
    name,
    reference,
    status,
    paymentStatus,
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.account_name,
        id: "account_name",
        header: "Account Name",
      },
      {
        accessorFn: (row: any) => row?.account_number,
        id: "account_number",
        header: "Account Number",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          parseFloat(row?.amount.toString()).toLocaleString("en-GH", {
            style: "currency",
            currency: "GHS",
          }),
        id: "amount",
        header: "Amount",
      },
      {
        accessorFn: (row) =>
          row?.status?.code === "COMPLETED" || row?.status?.code === "PAID" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ) : row?.status?.code === "PENDING" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-gray-800 bg-gray-500 bg-opacity-[15%]">
              {row?.status?.name}
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ),
        id: "status",
        header: "Status",
        size: 80,
      },

      {
        accessorFn: (row: any) => row?.remarks,
        id: "remarks",
        header: "Remarks",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "date",
        header: "Payment Date",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Payments</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={data}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <CouponsFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              nameInput={name}
              onNameInputChange={(e) => handleNameInput(e)}
              phoneInput={thePhoneNum}
              onPhoneInputChange={(e) => handlePhoneInput(e)}
              referenceInput={reference}
              onReferenceInputChange={(e) => handleReferenceInput(e)}
              statusInput={status}
              onStatusInputChange={(e) => handleStatusInput(e)}
              paymentStatusInput={paymentStatus}
              onPaymentStatusInputChange={(e) => handlePaymentStatusInput(e)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelCoupons;
