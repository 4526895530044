import { Input, Select, Option, Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { DateRangePicker } from "rsuite";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { ReactNode } from "react";

interface filterBtnProps {
  onHide: () => void;
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  nameInput: any;
  onNameInputChange: (value: any) => void;
  phoneInput: any;
  onPhoneInputChange: (value: any) => void;
  referenceInput: any;
  onReferenceInputChange: (value: any) => void;

  statusInput: any;
  onStatusInputChange: (value: any) => void;

  paymentStatusInput: any;
  onPaymentStatusInputChange: (value: any) => any;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;

  // Sets the position where the predefined range is displayed, the default is bottom.
  placement?: "bottom" | "left";
}

const GPRTUFilter = ({
  onHide,
  defaultDates,
  handleDateChange,
  nameInput,
  onNameInputChange,
  phoneInput,
  onPhoneInputChange,
  referenceInput,
  onReferenceInputChange,
  statusInput,
  onStatusInputChange,
  paymentStatusInput,
  onPaymentStatusInputChange,
}: filterBtnProps) => {
  const customRanges: Range[] = [
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: "This Week",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Last Week",
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: "This Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: "This Year",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: "Last Year",
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  return (
    <div className="px-3 py-[20px] border-l-[1px] border-gray-500 bg-white h-full overflow-y-auto">
      {/* header */}
      <div className="mb-2 flex justify-between items-center">
        <Typography variant="h5" color="blue-gray">
          Filter
        </Typography>
        <ChevronDoubleRightIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => onHide()}
        />
      </div>

      {/* content */}
      {/* date picker */}
      <div className="mt-5 w-full">
        <p>Date Rage</p>
        <DateRangePicker
          className="w-full"
          placement="leftStart"
          value={defaultDates}
          onChange={(e: any) => handleDateChange(e)}
          ranges={customRanges}
        />
      </div>

      {/* phone search */}
      <div className="mt-5 w-full">
        <p className="pb-1">Coupon Owner Name</p>
        <Input
          label="Enter name"
          type="text"
          value={nameInput}
          onChange={(e) => onNameInputChange(e)}
        />
      </div>

      {/* phone search */}
      <div className="mt-5 w-full">
        <p className="pb-1">Phone Number</p>
        <Input
          label="Enter number"
          type="text"
          value={phoneInput}
          onChange={(e) => onPhoneInputChange(e)}
        />
      </div>

      {/* serial search */}
      <div className="mt-5 w-full">
        <p className="pb-1">Serial Number</p>
        <Input
          label="Enter reference"
          type="text"
          value={referenceInput}
          onChange={(e) => onReferenceInputChange(e)}
        />
      </div>

      {/* status select */}
      <div className="mt-5 w-full">
        <p className="mb-2">Status</p>

        <Select
          label="Select Status"
          value={statusInput}
          onChange={(value: any) => onStatusInputChange(value)}
        >
          <Option value="">All</Option>
          <Option value="USED">Used</Option>
          <Option value="NOT_USED">Not Used</Option>
          <Option value="PARTIALLY_USED">Partially Used</Option>
        </Select>
      </div>
      <div className="mt-5 w-full">
        <p className="mb-2">Payment Status</p>
        <Select
          label="Select Payment Status"
          value={paymentStatusInput}
          onChange={(value: any) => onPaymentStatusInputChange(value)}
        >
          <Option value="">All</Option>
          <Option value="PENDING">Not Paid</Option>
          <Option value="COMPLETED">Paid</Option>
          <Option value="FAILED">Failed</Option>
        </Select>
      </div>
    </div>
  );
};

export default GPRTUFilter;
