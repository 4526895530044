import { Fragment, useEffect, useState, useMemo } from "react";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { useParams } from "react-router-dom";
import { Typography } from "@material-tailwind/react";
import { ArrowPathIcon, CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { getApiWithMs } from "../../../../../utils/api";
import { globalVariables } from "../../../../../helpers/globarVars";
import moment from "moment";
import MaterialReactTable from "material-react-table";
import CardShimmer from "./cardShimmer";
import { APP_URL, WEB_APP_URL } from "../../../../../helpers/constants";

const TripsManifets = () => {
    const { schId } = useParams();
    const [isFetchingDetails, setIsFetchingDetails] = useState(false);
    const [totalMinors, setTotalMinors] = useState(0);
    const [driverDetails, setDriverDetails] = useState();
    const [branch, setBranchDetails] = useState<any>();
    const [supervisor, setSupervisor] = useState();
    const [fieldOfficer, setFieldOfficer] = useState();
    const [capacity, setCapacity] = useState(0);
    const [status, setStatus] = useState("");
    const [schedule, setScheduleDetails] = useState<any>();
    const [arrival, setArrival] = useState("");
    const [departure, setDeparture] = useState("");
    const [busNumber, setBusNumber] = useState("");
    const [routeFrom, setRouteFrom] = useState("");
    const [routeTo, setRouteTo] = useState("");
    const [isFetchingPassengers, setIsFetchingPassengers] = useState(false);
    const [passengersTableData, setPassengersTableData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const reload = () => {
        fetchDetails();
        fetchPassengers();
    };

    const fetchDetails = async () => {
        setIsFetchingDetails(true);
        try {
            const response = await getApiWithMs(
                `${globalVariables.getSchedules}/${schId}/details`
            );
            setTotalMinors(response?.payload?.onboard_minors);
            setCapacity(response?.payload?.bus?.passenger_capacity);
            setScheduleDetails(response?.payload);
            setBranchDetails(response?.payload?.branch);
            setDriverDetails(response?.payload?.driver);
            setSupervisor(response?.payload.branch_supervisor);
            setFieldOfficer(response?.payload?.field_officer);
            setStatus(response?.payload?.status);
            setArrival(response?.payload?.estimated_arrival_date_time);
            setDeparture(response?.payload?.departures_at);
            setBusNumber(response?.payload?.bus?.reg_number);
            setRouteFrom(response?.payload?.route?.from?.name);
            setRouteTo(response?.payload?.route?.to?.name);
            setIsFetchingDetails(false);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchPassengers = async () => {
        const offset = pagination.pageIndex * pagination.pageSize;
        setIsFetchingPassengers(true);
        try {
            const response = await getApiWithMs(
                `${globalVariables.getSchedules}/${schId}/passengers?limit=${pagination.pageSize}&offset=${offset}`
            );

            setPassengersTableData(response?.payload?.items);
            setRowCount(response?.payload?.total);
            setIsFetchingPassengers(false);
        } catch (error) {
            console.log(error);
        }
    };

    //fetch details
    useEffect(() => {
        fetchDetails();
        fetchPassengers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schId, pagination.pageIndex, pagination.pageSize]);

    //handle on ticket click
    const onTicketClick = (ticketNum: any) => {
        window.open(`${WEB_APP_URL}/ticket?tn=${ticketNum}`, "_blank");
    };

    // table columns
    const columns = useMemo(
        () => [
            {
                accessorFn: (row: any) =>
                    `${row?.first_name} ${row?.last_name}`,
                id: "name",
                header: "Name",
                size: 120,
            },
            {
                accessorFn: (row: any) => row?.phone_number,
                id: "number",
                header: "Phone",
                size: 100,
            },
            {
                accessorFn: (row: any) => row?.ice_contact,
                id: "emergency",
                header: "Primary Emergency Contact",
                size: 100,
            },
            {
                accessorFn: (row: any) => row?.minors,
                id: "minors",
                header: "Minors",
                size: 10,
            },
            {
                accessorFn: (row: any) => row?.payment_method,
                id: "method",
                header: "Payment Method",
                size: 15,
            },
            {
                accessorFn: (row: any) => row?.seat?.number,
                id: "set",
                header: "Seat No.",
                size: 10,
            },
            {
                accessorFn: (row: any) =>
                    moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
                id: "enrolled",
                header: "Enrolled On",
                size: 120,
            },
            {
                accessorFn: (row: any) => (
                    <button
                        className="px-3 py-1 border-[1px] border-gray-500"
                        onClick={() => onTicketClick(row?.code)}
                    >
                        Ticket
                    </button>
                ),
                id: "actions",
                header: "Actions",
                size: 20,
            },
        ],
        []
    );

    return (
        <Fragment>
            <PageLayout>
                <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
                    <div className="h-full overflow-hidden">
                        <div className="h-full overflow-y-auto p-[20px]">
                            <div className="flex justify-between items-center">
                                <Typography variant="h4">Manifest</Typography>
                                <div>
                                    <button
                                        className="px-3 py-1 bg-oya-ghana-red text-white flex items-center gap-1 mb-3"
                                        onClick={reload}
                                    >
                                        <ArrowPathIcon className="w-5 h-5 " />
                                        Reload
                                    </button>
                                    <button
                                        className="px-3 py-1 bg-oya-ghana-red text-white flex items-center gap-1"
                                        onClick={() =>
                                            window.open(
                                                `${APP_URL}/busride/trip/${schId}`,
                                                "_blank"
                                            )
                                        }
                                    >
                                        <CloudArrowDownIcon className="w-5 h-5" />
                                        Download
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div>
                                    <Typography variant="h5">
                                        {branch?.name}
                                    </Typography>
                                    <Typography variant="h6">
                                        Trip Code: {schedule?.code}
                                    </Typography>
                                </div>
                                <div></div>
                            </div>

                            {/* stats card */}
                            <div className="grid grid-cols-4 mt-4 gap-4">
                                {/* route */}
                                <div className="px-[15px] pt-[15px] py-[40px] shadow-sm bg-white">
                                    <p className="text-lg font-semibold">
                                        Route
                                    </p>
                                    <div className="border-t-[1px] border-t-gray-400 pt-2">
                                        {isFetchingDetails ? (
                                            <CardShimmer />
                                        ) : (
                                            <>
                                                <p className="text-2xl font-semibold">
                                                    {rowCount} Passenger(s)
                                                </p>
                                                <p className="text-[16px] font-semibold">
                                                    Minors: {totalMinors}
                                                </p>
                                                <p className="text-[16px] font-semibold">
                                                    {routeFrom} - {routeTo}
                                                </p>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Status:
                                                    </span>{" "}
                                                    {status
                                                        .toLowerCase()
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        status
                                                            .slice(1)
                                                            .toLowerCase()}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* bus */}
                                <div className="px-[15px] pt-[15px] py-[40px] shadow-sm bg-white">
                                    <p className="text-lg font-semibold">
                                        Bus - {busNumber}
                                    </p>
                                    <div className="border-t-[1px] border-t-gray-400 pt-2">
                                        {isFetchingDetails ? (
                                            <CardShimmer />
                                        ) : (
                                            <>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Driver Name:
                                                    </span>{" "}
                                                    {driverDetails?.["name"]}
                                                </p>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Phone:
                                                    </span>{" "}
                                                    {driverDetails?.["phone"]}
                                                </p>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Bus Capacity:
                                                    </span>{" "}
                                                    {capacity}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Departure/Arrival */}
                                <div className="px-[15px] pt-[15px] py-[40px] shadow-sm bg-white">
                                    <p className="text-lg font-semibold">
                                        Departure/Arrival
                                    </p>
                                    <div className="border-t-[1px] border-t-gray-400 pt-2">
                                        {isFetchingDetails ? (
                                            <CardShimmer />
                                        ) : (
                                            <>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Departure:
                                                    </span>{" "}
                                                    {moment(departure).format(
                                                        "Do MMM YYYY"
                                                    )}
                                                    &ensp;
                                                    {moment(departure).format(
                                                        "hh:mm a"
                                                    )}
                                                </p>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Estimated Arrival:
                                                    </span>{" "}
                                                    {moment(arrival).format(
                                                        "Do MMM YYYY hh:mm a"
                                                    )}
                                                </p>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Scheduled On :
                                                    </span>
                                                    {moment(departure).format(
                                                        "Do MMM YYYY"
                                                    )}
                                                    &ensp;
                                                    {moment(departure).format(
                                                        "hh:mm a"
                                                    )}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Payments */}
                                <div className="px-[15px] pt-[15px] py-[40px] shadow-sm bg-white">
                                    <p className="text-lg font-semibold">
                                        Payments
                                    </p>
                                    <div className="border-t-[1px] border-t-gray-400 pt-2">
                                        {isFetchingDetails ? (
                                            <CardShimmer />
                                        ) : (
                                            <>
                                                <p className="text-[16px] font-semibold">
                                                    <span className="font-semibold">
                                                        Payments:
                                                    </span>
                                                </p>
                                                <p className="text-[16px] font-semibold">
                                                    <span className="font-semibold">
                                                        Cash:
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {/* Officers */}
                                <div className="px-[15px] pt-[15px] py-[40px] shadow-sm bg-white">
                                    <p className="text-lg font-semibold">
                                        Officers
                                    </p>
                                    <div className="border-t-[1px] border-t-gray-400 pt-2">
                                        {isFetchingDetails ? (
                                            <CardShimmer />
                                        ) : (
                                            <>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Field Officer:
                                                    </span>{" "}
                                                    {fieldOfficer?.["name"]}
                                                </p>
                                                <p className="text-[16px]">
                                                    <span className="font-semibold">
                                                        Supervisor:
                                                    </span>{" "}
                                                    {supervisor?.["name"]}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* table */}
                            <div className="mt-4 mb-20">
                                <MaterialReactTable
                                    enableColumnResizing
                                    columns={columns}
                                    data={passengersTableData}
                                    enableRowSelection={false}
                                    rowCount={rowCount}
                                    enableColumnActions={false}
                                    enableDensityToggle={false}
                                    enableFilters={false}
                                    enableFullScreenToggle={false}
                                    enableSorting={false}
                                    enableClickToCopy={false}
                                    manualPagination
                                    onPaginationChange={setPagination}
                                    state={{
                                        showProgressBars: isFetchingPassengers,
                                        pagination,
                                        density: "compact",
                                        isLoading: isFetchingPassengers,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PageLayout>
        </Fragment>
    );
};

export default TripsManifets;
