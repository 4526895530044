import { Fragment, useState } from "react";
import { Typography } from "@material-tailwind/react";
import DateRangeComponent from "../../../../components/general/dateRangePicker";

const OverviewFilterPane = () => {
  const [filterBtnClicked, setfilterBtnClicked] = useState<Boolean>(false);

  const handleFilterBtnClick = () => {
    setfilterBtnClicked(!filterBtnClicked);
  };

  return (
    <Fragment>
      <button
        className="bg-oya-ghana-red text-white px-3 py-1 lg:hidden absolute right-3 mt-[20px]"
        onClick={() => handleFilterBtnClick()}
      >
        Filter
      </button>
      <div
        className={`${
          filterBtnClicked
            ? "absolute right-0 lg:relative overflow-hidden"
            : "hidden lg:block"
        } px-3 py-[20px] border-l-[1px] border-gray-500 bg-white h-full`}
      >
        {/* header */}
        <div className="mb-2 flex justify-between items-center">
          <Typography variant="h5" color="blue-gray">
            Filter
          </Typography>
        </div>

        {/* date picker */}
        <div className="mt-5 w-full">
          <DateRangeComponent />
        </div>

        <div className="flex justify-end mt-5 lg:hidden">
          <button
            className="border-[1px] border-oya-ghana-red text-oya-ghana-red px-4 py-1 rounded-[5px] hover:bg-oya-ghana-red hover:text-white"
            onClick={() => handleFilterBtnClick()}
          >
            Apply
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default OverviewFilterPane;
