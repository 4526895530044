import { useState, useEffect, useMemo } from "react";
import { Typography } from "@material-tailwind/react";
import HiringFilterPane from "../components/hiringFilter";
import PageLayout from "../../../../components/layouts/pageLayout";
import { endOfDay, startOfDay } from "date-fns";
import MaterialReactTable from "material-react-table";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { globalVariables } from "../../../../helpers/globarVars";
import { getHiringsApi } from "../../../../utils/api";

const HiringList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [hiringData, setHiringData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates([]);
    } else {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates(selectedDate);
    }
  };

  //fetch hirings
  useEffect(() => {
    const fetchHirings = async () => {
      setIsFetching(true);
      try {
        const offset: any = pagination.pageIndex * pagination.pageSize;

        const response = await getHiringsApi(
          `${globalVariables.hiringsRoute}?filterobject=${JSON.stringify({
            limit: pagination.pageSize,
            skip: offset,
            order: { created_at: "DESC" },
            filter: {
              range: {
                created_at: [
                  defaultDates[0].toISOString(),
                  defaultDates[1].toISOString(),
                ],
              },
            },
          })}`
        );

        console.log(response);
        setHiringData(response?.payload);
        setRowCount(response?.total);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchHirings();
  }, [pagination.pageIndex, pagination.pageSize, defaultDates]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.creator?.name,
        id: "user",
        header: "User",
      },
      {
        accessorFn: (row: any) => row?.creator?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.creator?.email,
        id: "userr",
        header: "User",
      },
      {
        accessorFn: (row: any) => row?.source.place_name,
        id: "from",
        header: "From",
      },
      {
        accessorFn: (row: any) => row?.destination?.place_name,
        id: "to",
        header: "To",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Hirings</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={hiringData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <HiringFilterPane
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default HiringList;
