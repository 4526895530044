import { useState, useEffect, useMemo } from "react";
import { Typography } from "@material-tailwind/react";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { getApiWithMs } from "../../../../../utils/api";
import { globalVariables } from "../../../../../helpers/globarVars";
import { MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import GPRTUFilter from "./gprtuFilter";
import { formatPhoneNumber2 } from "../../../../../utils/functions";

const GPRTUMembers = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branchId, setBranchId] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [thePhoneNum, setThePhoneNum] = useState("")
  const [actualPhone, setActualPhone] = useState("")

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [gprtuData, setGprtuData] = useState([])
  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // handle onBranch select
  const onBranchSelect = (value: any) => {
    console.log(value);
    setSelectedBranch(value);
    setBranchId(value.value);
  };
  // handle onPoint select
  const onRegionChange = (value: any) => {
    console.log(value);
    setSelectedRegion(value);
  };

  const handlePhoneInput = (e: any) => {
    console.log(e.target.value)
    setThePhoneNum(e.target.value)
    if (thePhoneNum.length > 3) {
      setActualPhone(e.target.value)
    }
  }

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (defaultDates.length !== 0) {
          console.log("run with date")
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (actualPhone !== "") {
          defaultFilters.push(
            {
              f: "phone",
              o: "contains",
              p: [formatPhoneNumber2(actualPhone)],
            }
          );
        }

        if (selectedRegion !== "") {
          defaultFilters.push(
            {
              f: "REGION",
              o: "contains",
              p: [JSON.stringify(selectedRegion)],
            }
          );
        }

        const response = await getApiWithMs(
          `${globalVariables.get_gprtu_members}?limit=${pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        console.log(response);
        setGprtuData(response?.payload?.items);
        setRowCount(response?.payload?.total);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };

    fetchTableData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    branchId,
    selectedRegion,
    actualPhone
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.membership_id,
        id: "memId",
        header: "Membership Id",
      },
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.branch,
        id: "branch",
        header: "Branch",
      },
      {
        accessorFn: (row: any) => row?.region?.name,
        id: "region",
        header: "Region",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${hideFilter ? "" : "grid grid-cols-4 gap-4"
            } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                GPRTU Members
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${hideFilter ? "block" : "block lg:hidden"
                  } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={gprtuData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
              } h-full`}
          >
            <GPRTUFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              selectedBranch={selectedBranch}
              onBranchChange={(value) => onBranchSelect(value)}
              isLoadingPointActive={!selectedBranch}
              branchId={branchId}
              selectedRegion={selectedRegion}
              onRegionChange={(value) => onRegionChange(value)}
              phoneInput={thePhoneNum}
              onPhoneInputChange={(e) => handlePhoneInput(e)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default GPRTUMembers;
