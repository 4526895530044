import { Input, Typography } from "@material-tailwind/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { globalVariables } from "../../../../../../helpers/globarVars";
import { getApiWithMs } from "../../../../../../utils/api";
import PageLayout from "../../../../../../components/layouts/pageLayout";
import {
  CloudArrowDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";

const VehicleStatement = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [vehiclesData, setVehiclesData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [model, setModel] = useState("");

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        if (model) {
          console.log(model);

          const filters = [
            {
              f: "model",
              o: "contains",
              p: [model],
              c: "OR",
            },
            {
              f: "reg_number",
              o: "contains",
              p: [model],
            },
          ];
          const response = await getApiWithMs(
            `${globalVariables.vehiclesStatement}?filters=${JSON.stringify(
              filters
            )}`
          );
          setVehiclesData(response?.payload?.items || []);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        } else {
          const response = await getApiWithMs(
            `${globalVariables.vehiclesStatement}?sorting=created_at:desc&limit=${pagination.pageSize}&offset=${offset}`
          );
          console.log(response);
          setVehiclesData(response?.payload?.items);
          setRowCount(response?.payload?.total);
          setIsFetching(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };
    fetchTableData();
  }, [model, pagination.pageIndex, pagination.pageSize]);

  // table columns
  const columns = useMemo(
    () => [
      // {
      //   accessorFn: (row: any) => row?.id,
      //   id: "id",
      //   header: "ID",
      //   size: 10
      // },
      {
        accessorFn: (row: any) => row?.model,
        id: "model",
        header: "Model",
        size: 150,
      },
      {
        accessorFn: (row: any) => row?.reg_number,
        id: "reg",
        header: "Registration",
        size: 150,
      },
      {
        accessorFn: (row: any) => row?.passenger_capacity,
        id: "capacity",
        header: "Capacity",
        size: 100,
      },
      {
        accessorFn: (row: any) => row?.bus_company?.name,
        id: "company",
        header: "Bus Company",
        size: 100,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.rw_exp_date).format("Do MMM YYYY hh:mm a"),
        id: "road",
        header: "Road Worthy Exp Date",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.insurance_exp_date).format("Do MMM YYYY hh:mm a"),
        id: "ins",
        header: "Insurance Exp Date",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
        id: "created",
        header: "Created Date",
        size: 120,
      },
      {
        accessorFn: (row: any) => (
          <button
            className="flex items-center gap-2 border-[1px] border-oya-ghana-red px-3 py-1 text-oya-ghana-red"
            onClick={() => handleDownload(row?.id)}
          >
            <CloudArrowDownIcon className="w-5 h-5" />
            Download
          </button>
        ),
        id: "statement",
        header: "Statement",
        size: 120,
      },
    ],
    []
  );

  // handle download
  const handleDownload = (theId: any) => {
    console.log(theId);
    try {
      const response = getApiWithMs(
        `${globalVariables.exportVehicles}/${theId}/trips-export`
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <PageLayout>
        <div className="h-full pl-[20px] py-[20px] bg-[#f5f5f5] overflow-hidden">
          <div className="h-full overflow-y-auto pr-4 pb-20">
            <div className="flex gap-4 items-center mb-3">
              <Typography variant="h4">Statements / Vehicles</Typography>
            </div>

            <div className="flex justify-end mb-3 w-full">
              <div>
                <Input
                  type="text"
                  placeholder="Search model or registration number"
                  variant="static"
                  value={model}
                  onChange={(e: any) => setModel(e.target.value)}
                  icon={<MagnifyingGlassIcon className="w-5 h-5" />}
                />
              </div>
            </div>
            <div className="mb-20">
              <MaterialReactTable
                enableColumnResizing
                columns={columns}
                data={vehiclesData}
                enableRowSelection={false}
                rowCount={rowCount}
                enableColumnActions={false}
                enableDensityToggle={false}
                enableFilters={false}
                enableFullScreenToggle={false}
                enableSorting={false}
                manualPagination
                onPaginationChange={setPagination}
                state={{
                  showProgressBars: isFetching,
                  pagination,
                  density: "compact",
                  isLoading: isLoading,
                }}
              />
            </div>
          </div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default VehicleStatement;
