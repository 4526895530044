import { useState, useEffect, useMemo } from "react";
import { Typography } from "@material-tailwind/react";
import PassengersFilter from "./passengersFilter";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { globalVariables } from "../../../../../helpers/globarVars";
import { getApiWithMs } from "../../../../../utils/api";
import { MaterialReactTable } from "material-react-table";
import moment from "moment";
import { endOfDay, startOfDay } from "date-fns";
import { formatDateRange } from "../../../../../helpers/utils";

const AllPassengerList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [passengerData, setPassengerData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        defaultFilters.push(
          {
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          },
          { f: "onboard", o: "=", p: [true] }
        );

        // if (actualPhoneNum !== "") {
        //   defaultFilters.push(
        //     { f: "onboard", o: "=", p: [true] },
        //     {
        //       f: "phone",
        //       o: "contains",
        //       p: [formatPhoneNumber(phoneNumber)],
        //     }
        //   );
        // }

        const response = await getApiWithMs(
          `${
            globalVariables.getPassengersRoute
          }?sorting=created_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        // console.log(response);
        setPassengerData(response?.payload?.items);
        setRowCount(response?.payload?.total);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTableData();
  }, [pagination.pageIndex, pagination.pageSize, defaultDates]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.phone,
        id: "phone",
        header: "Contact",
      },
      {
        accessorFn: (row: any) => row?.ice_contact,
        id: "iceContact",
        header: "ICE 1",
      },
      {
        accessorFn: (row: any) => row?.seat?.number,
        id: "seat",
        header: "Seat",
        size: 5,
      },
      {
        accessorFn: (row: any) => row?.ticket_no,
        id: "tic",
        header: "Ticket No.",
      },
      {
        accessorFn: (row: any) => row?.payment_method,
        id: "meth",
        header: "Payment Method",
      },
      {
        accessorFn: (row: any) => row?.minors,
        id: "minors",
        header: "Minors",
        size: 5,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "date",
        header: "Enrolled On",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                Passengers{" "}
                {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={passengerData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <PassengersFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              // phoneNumber={phoneNumber}
              // onPhoneChange={(e: any) => handlePhoneInput(e)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AllPassengerList;
