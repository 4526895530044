interface cardProps {
  icon: any;
  title: string;
  onClick: () => void;
}

const ReportCardComponent = ({ title, icon, onClick }: cardProps) => {
  return (
    <div className='px-[20px] py-[30px] bg-white shadow-lg rounded-[10px] flex justify-between items-center'>
      {icon}
      <div>
        <p className='text-xl font-medium'>{title}</p>

        <button
          onClick={onClick}
          className='px-4 py-2 bg-transparent border border-oya-ghana-red text-oya-ghana-red mt-4'
        >
          View Report
        </button>
      </div>
    </div>
  );
};

export default ReportCardComponent;
