import { redirect } from '../utils/functions';
import { ACCOUNTS_URL, COOKIES_DOMAIN } from './constants';

export const getCookie = (cookieName: any) => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');
  for (let index = 0; index < cookiesArray.length; index++) {
    let cookie = cookiesArray[index];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

const deleteCookie = (name: string) => {
  window.document.cookie = `${name}=;domain=${COOKIES_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const logout = () => {
  // deleteCookie("oya_user");
  // deleteCookie("oya_token");
  // deleteCookie("oya_usr");
  // deleteCookie("oya_selected_user_role");
  // deleteCookie("oya_selected_service");

  window.localStorage.clear();
  redirect(`${ACCOUNTS_URL}/logout?appref=${window.location.origin}/`);
  //?appref=${window.location.origin}/
};

export const switchRole = () => {
  deleteCookie('oya_selected_user_role');
  window.location.reload();
};

export const setCookie = (cname: string, cvalue: string, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = `oya_${cname}=${cvalue};${expires};path=/;domain=${COOKIES_DOMAIN}`;
};

export const setCookieJson = (cname: string, cvalue: string, exdays = 7) => {
  setCookie(cname, JSON.stringify(cvalue), exdays);
};

export const formatDateRange = (startDate?: Date, endDate?: Date): string => {
  if (!startDate && !endDate) {
    return 'All Time';
  }

  const getFormattedDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const meridiem = hours >= '12' ? 'pm' : 'am';
    const formattedHours = parseInt(hours) % 12 || 12;

    return `${day}${
      day === 1 || day === 21 || day === 31
        ? 'st'
        : day === 2 || day === 22
        ? 'nd'
        : day === 3 || day === 23
        ? 'rd'
        : 'th'
    } ${month}, ${year} ${formattedHours}:${minutes}${meridiem}`;
  };

  if (startDate && endDate) {
    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);

    return `${formattedStartDate} - ${formattedEndDate}`;
  } else if (startDate) {
    return `From Beginning - ${getFormattedDate(startDate)}`;
  } else if (endDate) {
    return `Till ${getFormattedDate(endDate)}`;
  }

  return 'Invalid date range';
};
