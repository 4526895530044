import { useEffect, useState, useMemo } from "react";
import { Typography } from "@material-tailwind/react";
import PaymentsFilterPane from "../../../parcelService/components/paymentsFilter";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import moment from "moment";
import { globalVariables } from "../../../../../helpers/globarVars";
import { getApiWithMs } from "../../../../../utils/api";
import { MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import { formatDateRange } from "../../../../../helpers/utils";
import StatsCard from "../../../fuelService/components/statsCard";

export enum PaymentStatusNumber {
  CANCELLED = 0,
  PENDING = 1,
  PART_PAYMENT = 2,
  COMPLETED = 3,
  FAILED = 4,
}
const BusRidesPayments = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState("MOBILE_MONEY");
  const [transType, setTransType] = useState("debit");
  const [totalAmount, setTotalAmount] = useState(0);

  // handle payment method change
  const handlePayMethodChange = (selectedMethod: string) => {
    setPaymentMethod(selectedMethod);
  };

  // handle transaction type change
  const handleTransChange = (selectedType: string) => {
    setTransType(selectedType);
  };

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [paymentData, setPaymentData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (defaultDates.length !== 0) {
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (paymentMethod) {
          let value: string[] = [];
          switch (paymentMethod) {
            case "MOBILE_MONEY":
              value = ["VODAFONE_GH", "MTN_GH", "TIGO_GH", "MOBILE_MONEY"];
              break;
            case "CASH_GH":
              value = ["CASH_GH", "CASH"];
              break;
            default:
              break;
          }

          defaultFilters.push({
            f: "method",
            o: "in",
            p: value,
          });
        }
        if (transType) {
          defaultFilters.push({
            f: "type",
            o: "=",
            p: [transType],
          });
        }
        const [listResponse, countResponse] = await Promise.all([
          await getApiWithMs(
            `${
              globalVariables.getPaymentsRoute
            }?sorting=created_at:desc&filters=${JSON.stringify(
              defaultFilters
            )}&offset=${offset}&page=${pagination.pageIndex}&limit=${
              pagination.pageSize
            }`
          ),
          await getApiWithMs(
            `${
              globalVariables.getPaymentsCountsRoute
            }?sorting=created_at:desc&filters=${JSON.stringify(
              defaultFilters
            )}&offset=${offset}&page=${pagination.pageIndex}&limit=${
              pagination.pageSize
            }`
          ),
        ]);
        // const response = ;
        setPaymentData(listResponse?.payload?.items ?? []);
        setRowCount(listResponse?.payload?.total);
        setTotalAmount(countResponse?.payload?.total_amount);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };

    fetchTableData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    paymentMethod,
    transType,
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.user?.name,
        id: "user",
        header: "Initiated By",
      },
      {
        accessorFn: (row: any) => row?.account_name,
        id: "name",
        header: "Paid/Received By",
      },
      {
        accessorFn: (row: any) => row?.account_number,
        id: "number",
        header: "Account Number",
      },

      {
        accessorFn: (row: any) => `${row?.currency} ${row?.amount}`,
        id: "amount",
        header: "Amount",
      },
      {
        accessorFn: (row: any) =>
          row?.status === 3 ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              Paid
            </div>
          ) : row?.status === 1 ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-yellow-800 bg-yellow-500 bg-opacity-[15%]">
              Pending
            </div>
          ) : row?.status === 4 ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[15%]">
              Failed
            </div>
          ) : row?.status === 0 ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-brown-800 bg-brown-500 bg-opacity-[15%]">
              Canceled
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-gray-800 bg-gray-500 bg-opacity-[10%]">
              {row?.payment_status?.name}
            </div>
          ),
        id: "payment_status",
        header: "Payment",
        size: 80,
      },
      {
        accessorFn: (row: any) =>
          row?.type === "debit"
            ? "Debit"
            : row?.type === "credit"
            ? "Funds Transfer"
            : "",
        id: "type",
        header: "Type",
      },
      {
        accessorFn: (row: any) => row?.method,
        id: "method",
        header: "Method",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "date",
        header: "Payment Date",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">
                Payments{" "}
                {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
              </Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>
            <div className="lg:grid lg:grid-cols-3 mt-2 gap-5">
              <StatsCard
                label="Total Amount"
                value={totalAmount}
                loading={isFetching}
              />
            </div>

            {/* Table */}
            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={paymentData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    isLoading: isLoading,
                    pagination,
                    density: "compact",
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <PaymentsFilterPane
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              payMethod={paymentMethod}
              onPayMethodChange={(value: any) => handlePayMethodChange(value)}
              transType={transType}
              onTransChange={(value: any) => handleTransChange(value)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BusRidesPayments;
