import { Menu, Typography, MenuItem } from "@material-tailwind/react";
import PageLayout from "../../../../components/layouts/pageLayout";
import { useState, useEffect, useMemo } from "react";
import { ChevronDoubleLeftIcon, LinkIcon } from "@heroicons/react/24/outline";
import {
  getFuelBackOfficeMSApi,
  postFuelBackOfficeMSApi,
  postFuelMSApi,
} from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";
import { MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import CouponsFilter from "./couponsFilter";
import { formatPhoneNumber2 } from "../../../../utils/functions";
import moment from "moment";
import StatsCard from "../components/statsCard";
import { DiscountOutlined } from "@mui/icons-material";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

const FuelCoupons = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [thePhoneNum, setThePhoneNum] = useState("");
  const [actualPhone, setActualPhone] = useState("");

  const [name, setName] = useState("");
  const [reference, setReference] = useState("");
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("COMPLETED");

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const navigate = useNavigate();

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountLeft, setTotalAmountLeft] = useState(0);
  const [totalAmountUsed, setTotalAmountUsed] = useState(0);

  const [totalNumber, setTotalNumber] = useState(0);

  const [defaultDates, setDefaultDates] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const handlePhoneInput = (e: any) => {
    setThePhoneNum(e.target.value);
    if (thePhoneNum.length > 1) {
      setActualPhone(e.target.value);
    }
  };

  const handleNameInput = (e: any) => {
    setName(e.target.value);
  };

  const handleReferenceInput = (e: any) => {
    setReference(e.target.value);
  };

  const handleStatusInput = (value: any) => {
    setStatus(value);
  };

  const handlePaymentStatusInput = (value: any) => {
    setPaymentStatus(value);
  };

  const downloadPdf = async (coupon: any) => {
    window.open(`${coupon?.pdf_url}`, "_blank");
  };
  const generatePdf = async (coupon: any) => {
    setIsFetching(true);

    try {
      await postFuelMSApi(
        `${globalVariables.downloadFuelCoupons}/${coupon?.id}/generate-pdf`
      );
      setTimeout(async () => {
        await fetchTableData();
      }, 3000);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
      });
    }
    setIsFetching(false);
  };

  const applyDiscount = async (coupon: any) => {
    setIsFetching(true);

    try {
      await postFuelBackOfficeMSApi(
        `${globalVariables.getFuelCoupons}/${coupon?.id}/apply-discount`
      );
      await fetchTableData();
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message,
      });
    }
    setIsFetching(false);
  };
  const fetchTableData = async () => {
    setIsFetching(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (defaultDates.length !== 0) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      if (actualPhone) {
        defaultFilters.push({
          f: "owner.phone",
          o: "contains",
          p: [formatPhoneNumber2(actualPhone)],
        });
      }

      if (name) {
        defaultFilters.push({
          f: "owner.name",
          o: "contains",
          p: [name],
        });
      }

      if (reference) {
        defaultFilters.push({
          f: "reference",
          o: "contains",
          p: [reference],
        });
      }

      if (status) {
        defaultFilters.push({
          f: "status",
          o: "=",
          p: [status],
        });
      }

      if (paymentStatus) {
        defaultFilters.push({
          f: "payment.status",
          o: "=",
          p: [paymentStatus],
        });
      }

      const [listResponse, countResponse] = await Promise.all([
        await getFuelBackOfficeMSApi(
          `${globalVariables.getFuelCoupons}?limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        ),
        await getFuelBackOfficeMSApi(
          `${
            globalVariables.getFuelCouponsCountsRoute
          }?sorting=created_at:desc&filters=${JSON.stringify(defaultFilters)}`
        ),
      ]);
      setData(listResponse?.payload?.items || []);
      setRowCount(listResponse?.payload?.total);
      setTotalAmount(countResponse?.payload?.total_amount);
      setTotalAmountLeft(countResponse?.payload?.total_amount_left);
      setTotalAmountUsed(countResponse?.payload?.total_amount_used);
      setTotalNumber(countResponse?.payload?.total_number);
      setIsFetching(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };

  // fetch data
  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualPhone,
    name,
    reference,
    status,
    paymentStatus,
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "date_purchased",
        header: "Date Purchased",
        size: 200,
      },
      {
        accessorFn: (row: any) => `${row?.currency} ${row?.price}`,
        id: "price",
        header: "Amount",
        size: 100,
      },
      {
        accessorFn: (row: any) => `${row?.currency} ${row?.amount_used}`,
        id: "amount_used",
        header: "Used",
        size: 100,
      },
      {
        accessorFn: (row: any) =>
          `${row?.discount?.currency ? row?.discount?.currency : ""} ${
            row?.discount?.amount ? row?.discount?.amount : ""
          }`,
        id: "discount",
        header: "Discount",
        size: 100,
      },
      {
        accessorFn: (row: any) => `${row?.currency} ${row?.amount_left}`,
        id: "amount_left",
        header: "Left",
        size: 100,
      },
      {
        accessorFn: (row: any) => row?.owner?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.owner?.phone,
        id: "phone",
        header: "Phone",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.reference,
        id: "reference",
        header: "Serial No.",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.fuel_type?.name,
        id: "fuel_type",
        header: "Fuel Type",
        size: 120,
      },
      {
        accessorFn: (row) =>
          row?.payment_status?.code === "COMPLETED" ||
          row?.payment_status?.code === "PAID" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              {row?.payment_status?.name}
            </div>
          ) : row?.payment_status?.code === "PENDING" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-gray-800 bg-gray-500 bg-opacity-[15%]">
              {row?.payment_status?.name}
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]">
              {row?.payment_status?.name}
            </div>
          ),
        id: "payment_status",
        header: "Payment",
      },
      {
        accessorFn: (row) =>
          row?.status?.code === "USED" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-green-800 bg-green-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ) : row?.status?.code === "NOT_USED" ? (
            <div className="px-3 py-1 w-fit rounded-[30px] text-red-800 bg-red-500 bg-opacity-[10%]">
              {row?.status?.name}
            </div>
          ) : (
            <div className="px-3 py-1 w-fit rounded-[30px] text-yellow-800 bg-yellow-500 bg-opacity-[15%]">
              {row?.status?.name}
            </div>
          ),
        id: "status",
        header: "Status",
        size: 115,
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Coupons</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>
            <div className="lg:grid lg:grid-cols-4 mt-2 gap-5">
              <StatsCard
                label="No. of Coupons"
                value={totalNumber}
                loading={isLoading}
              />
              <StatsCard
                label="Amount"
                value={totalAmount}
                loading={isLoading}
                type="currency"
              />
              <StatsCard
                label="Amount Used"
                value={totalAmountUsed}
                loading={isLoading}
                type="currency"
              />
              <StatsCard
                label="Amount Unused"
                value={totalAmountLeft}
                loading={isLoading}
                type="currency"
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={data}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  enableRowActions={true}
                  renderRowActionMenuItems={({ closeMenu, row }) => [
                    <Menu>
                      <MenuItem
                        key={1}
                        onClick={() => {
                          navigate(`/fuel/coupons/${row.original?.id}/details`);
                          closeMenu();
                        }}
                        className="flex items-center gap-2"
                      >
                        <LinkIcon className="w-5 h-5" />
                        View details
                      </MenuItem>
                      {!row.original?.discount && (
                        <MenuItem
                          key={1}
                          onClick={() => {
                            applyDiscount(row.original);
                            closeMenu();
                          }}
                        >
                          <DiscountOutlined />
                          Apply Discount
                        </MenuItem>
                      )}
                      {!row.original?.pdf_url &&
                        row.original?.payment_status?.code === "COMPLETED" && (
                          <MenuItem
                            key={1}
                            onClick={() => {
                              generatePdf(row.original);
                              closeMenu();
                            }}
                          >
                            <DiscountOutlined />
                            Generate PDF File
                          </MenuItem>
                        )}
                      {row.original?.pdf_url && (
                        <>
                          <MenuItem
                            key={1}
                            onClick={() => {
                              downloadPdf(row.original);
                              closeMenu();
                            }}
                          >
                            <DiscountOutlined />
                            Download PDF File
                          </MenuItem>
                          <MenuItem
                            key={1}
                            onClick={() => {
                              generatePdf(row.original);
                              closeMenu();
                            }}
                          >
                            <DiscountOutlined />
                            Regenerate PDF File
                          </MenuItem>
                        </>
                      )}
                    </Menu>,
                  ]}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                    columnPinning: {
                      left: ["mrt-row-expand", "mrt-row-select"],
                      right: ["mrt-row-actions"],
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <CouponsFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              nameInput={name}
              onNameInputChange={(e) => handleNameInput(e)}
              phoneInput={thePhoneNum}
              onPhoneInputChange={(e) => handlePhoneInput(e)}
              referenceInput={reference}
              onReferenceInputChange={(e) => handleReferenceInput(e)}
              statusInput={status}
              onStatusInputChange={(e) => handleStatusInput(e)}
              paymentStatusInput={paymentStatus}
              onPaymentStatusInputChange={(e) => handlePaymentStatusInput(e)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelCoupons;
