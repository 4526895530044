import {
  Input,
  Option,
  Select,
  Typography,
  Switch,
} from "@material-tailwind/react";
import {
  ChevronDoubleRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { DateRangePicker } from "rsuite";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { ReactNode } from "react";
import BranchFilterSelect from "../../components/branchFilter";
import LodingPointSelect from "../../components/lodingPointFilter";
import FieldOfficerSelect from "../../components/fieldOfficerFilter";
import CompanyFilterSelect from "../../components/companyFilter";
// import { Switch } from "antd";

interface filterProps {
  onHide: () => void;
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  status: string;
  onStatuschange: (value: string) => void;
  busNum: string;
  onBusNumChange: (value: string) => void;
  selectedBranch: any;
  onBranchChange: (value: any) => void;
  isLoadingPointActive: any;

  branchId: any;
  selectedPoint: any;
  selectedFieldOfficer: any;
  onPointChange: (value: any) => void;
  onFieldOfficerChange: (value: any) => void;

  tripCodeInput: any;
  onTripCodeInputChange: (value: any) => void;

  selectedCompany: any;
  onCompanyChange: (value: any) => void;

  useTestCompanies: any;
  handleCompanyTestChange: (value: any) => void;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;

  // Sets the position where the predefined range is displayed, the default is bottom.
  placement?: "bottom" | "left";
}

const AllTripsFilter = ({
  onHide,
  defaultDates,
  handleDateChange,
  status,
  onStatuschange,
  busNum,
  onBusNumChange,
  selectedBranch,
  onBranchChange,
  isLoadingPointActive,

  branchId,
  selectedPoint,
  selectedFieldOfficer,
  onFieldOfficerChange,
  onPointChange,

  tripCodeInput,
  onTripCodeInputChange,

  selectedCompany,
  onCompanyChange,

  useTestCompanies,
  handleCompanyTestChange,
}: filterProps) => {
  const customRanges: Range[] = [
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: "This Week",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Last Week",
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: "This Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: "This Year",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: "Last Year",
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  return (
    <div className="px-3 border-l-[1px] border-gray-500 bg-white min-h-full pb-[8rem]">
      {/* header */}
      <div className="mb-2 pt-7 flex justify-between items-center">
        <Typography variant="h5" color="blue-gray">
          Filter
        </Typography>
        <ChevronDoubleRightIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => onHide()}
        />
      </div>

      {/* content */}
      {/* date picker */}
      <div className="mt-5 w-full">
        <Typography className="mb-3" variant="h6">
          General
        </Typography>

        <p>Date Range</p>
        <DateRangePicker
          className="w-full"
          placement="leftStart"
          value={defaultDates}
          onChange={(e: any) => {
            if (e) {
              handleDateChange(e);
            }
          }}
          ranges={customRanges}
        />
      </div>

      {/* status select */}
      <div className="mt-5 w-full">
        <p className="mb-2">Filter by status</p>

        <Select
          label="Select Status"
          value={status}
          onChange={(value: any) => onStatuschange(value)}
        >
          <Option value="">All</Option>
          <Option value="SCHEDULED">Scheduled</Option>
          <Option value="SCALED">Scaled</Option>
          <Option value="LOADING">Loading</Option>
          <Option value="LOADED">Loaded/Awayed</Option>
        </Select>
      </div>

      {/* reg no. input */}
      <div className="mt-5 w-full">
        <p>Filter by bus number</p>
        <Input
          type="text"
          placeholder="Example: GR 1234-20"
          icon={<MagnifyingGlassIcon />}
          value={busNum}
          onChange={(e) => onBusNumChange(e.target.value)}
        />
      </div>

      <div className="mt-5 w-full">
        <p className="pb-1">Trip Code</p>
        <Input
          label="Enter Trip Code"
          type="text"
          value={tripCodeInput}
          onChange={(e) => onTripCodeInputChange(e)}
        />
      </div>

      <Typography className="mt-5" variant="h6">
        Specifics
      </Typography>

      <div className="mt-2 mb-5 w-full">
        <CompanyFilterSelect
          selectedBranch={selectedCompany}
          onBranchChange={(value) => onCompanyChange(value)}
          includeTestCompanies={useTestCompanies}
        />
        <div className="mt-3">
          <Switch
            checked={useTestCompanies}
            label="Show Test Companies"
            onChange={handleCompanyTestChange}
          />
        </div>
      </div>
      {selectedCompany && (
        <div className="mt-2 w-full">
          <BranchFilterSelect
            selectedBranch={selectedBranch}
            onBranchChange={(value) => onBranchChange(value)}
            selectedCompany={selectedCompany?.value}
          />
        </div>
      )}
      {selectedCompany && selectedBranch && (
        <div className="mt-5 w-full">
          <FieldOfficerSelect
            selectedPoint={selectedFieldOfficer}
            onPointChange={(value) => onFieldOfficerChange(value)}
            selectedBranch={selectedBranch.value}
          />
        </div>
      )}
      {selectedCompany && selectedBranch && (
        <div className="mt-5 w-full m-2">
          <LodingPointSelect
            isLoadingPointActive={isLoadingPointActive}
            branchId={branchId}
            selectedPoint={selectedPoint?.value}
            onPointChange={(value) => onPointChange(value)}
          />
        </div>
      )}

      <div className="h-[20rem] w-4" />
    </div>
  );
};

export default AllTripsFilter;
