import axios from "axios";
import { getCookie } from "../helpers/utils";
import {
  API_URL,
  AUTH_MS_URL,
  HIRINGS_URL,
  OYA_PAYMENT_SERVICE_TOKEN,
  PARCELS_URL,
  PAYMENT_URL,
  RIDE_MS_URL,
  FUEL_BACKOFFICE_MS,
  FUEL_MS_URL,
} from "../helpers/constants";

const token = getCookie("oya_token") || "";

export const apiHeaders = (type = "") => {
  const token = getCookie("oya_token") || "";
  if (type === "file") {
    return {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }
};

export const getApi = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${API_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const getApiWithMs = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${RIDE_MS_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};
// export const getDownloadApiWithMs = async (path, params = {}, options = {}) => {
//   const token = getCookie("oya_token") || "";
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     responseType: "blob",
//     "Content-Disposition": "attachment; filename=template.xlsx",
//     "Content-Type":
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   };
//   const url = `${RIDE_MS_URL}${path}`;
//   return axios({
//     method: "GET",
//     url,
//     headers,
//     params,
//   });
// };

export const getDownloadApiWithMs = (url) => {
  const token = getCookie("oya_token") || "";
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },

    responseType: "arraybuffer",
  };
  return axios.get(`${RIDE_MS_URL}${url}`, config);
};

export const fuelBackofficeDownloadApi = (route) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },

    responseType: "arraybuffer",
  };

  return axios.get(`${FUEL_BACKOFFICE_MS}${route}`, config);
};

export const rideMsDownloadApi = (route) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Disposition": "attachment; filename=template.xlsx",
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },

    responseType: "arraybuffer",
  };

  return axios.get(`${RIDE_MS_URL}${route}`, config);
};

export const getFuelBackOfficeMSApi = async (
  path,
  params = {},
  options = {}
) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${FUEL_BACKOFFICE_MS}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const postFuelBackOfficeMSApi = async (
  path,
  params = {},
  options = {}
) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${FUEL_BACKOFFICE_MS}${path}`;
  return axios({
    method: "POST",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const postFuelMSApi = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${FUEL_MS_URL}${path}`;
  return axios({
    method: "POST",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const getAuthApi = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${AUTH_MS_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const getPaymentApi = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = {
    ...headers,
    ...options,
    "OYA-PAYMENT-SERVICE-TOKEN": OYA_PAYMENT_SERVICE_TOKEN,
  };
  const url = `${PAYMENT_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};

export const getParcelsApi = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${PARCELS_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};
export const getHiringsApi = async (path, params = {}, options = {}) => {
  let headers = apiHeaders();
  headers = { ...headers, ...options };
  const url = `${HIRINGS_URL}${path}`;
  return axios({
    method: "GET",
    url,
    headers,
    params,
  })
    .then((response) => {
      const res = response.data;
      return res;
    })
    .catch((error) => {
      return { status: 606, result: "Network request failed", error };
    });
};
