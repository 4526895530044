import { DateRangePicker } from "rsuite";

// interface dateSelectorProps {
//   defaultDates?: [Date, Date];
//   handleDateChange: (value: [Date, Date]) => void;
// }

const DateRangeComponent = () => {
    return (
        <>
            <p>Date Range</p>
            <DateRangePicker
                className="w-full"
                placement="leftStart"
                // value={defaultDates}
                // onChange={(e: any) => handleDateChange(e)}
            />
        </>
    );
};

export default DateRangeComponent;
