import { useState, useEffect, useMemo } from "react";
import { Typography } from "@material-tailwind/react";
import ParcelFilterPane from "../components/parcelFilter";
import PageLayout from "../../../../components/layouts/pageLayout";
import { endOfDay, startOfDay } from "date-fns";
import MaterialReactTable from "material-react-table";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { getParcelsApi } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

const ParcelList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [parcelData, setPacelData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [refNumber, setRefNumber] = useState("");
  const [actualRefNum, setActualRefNum] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates([]);
    } else {
      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates(selectedDate);
    }
  };

  // ref input
  const handleRefInput = (e: any) => {
    e.preventDefault();
    setRefNumber(e.target.value);
    if (refNumber.length >= 10) {
      setActualRefNum(refNumber);
    } else if (refNumber.length < 5) {
      setActualRefNum("");
    } else {
      setActualRefNum("");
    }
  };

  // fetch parcels
  useEffect(() => {
    const fetchParcels = async () => {
      setIsFetching(true);
      try {
        const offset: any = pagination.pageIndex * pagination.pageSize;

        if (defaultDates) {
          // check for reference input
          if (actualRefNum) {
            const response = await getParcelsApi(
              `${globalVariables.parcelsRoute}?sorting=created_at:desc&page=${
                pagination.pageIndex
              }&limit=${
                pagination.pageSize
              }&ref=${actualRefNum}&offset=${parseInt(offset)}`
            );

            console.log(response);
            setPacelData(response?.payload?.items);
            setRowCount(response?.payload?.total);
            setIsFetching(false);
            setIsLoading(false);
          } else {
            const response = await getParcelsApi(
              `${globalVariables.parcelsRoute}?sorting=created_at:desc&page=${
                pagination.pageIndex
              }&start_date=${encodeURIComponent(
                defaultDates[0].toISOString()
              )}&end_date=${encodeURIComponent(
                defaultDates[1].toISOString()
              )}&limit=${pagination.pageSize}&offset=${parseInt(offset)}`
            );

            console.log(response);
            setPacelData(response?.payload?.items);
            setRowCount(response?.payload?.total);
            setIsFetching(false);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchParcels();
  }, [pagination.pageIndex, pagination.pageSize, defaultDates, actualRefNum]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.reference,
        id: "reference",
        header: "Reference",
      },
      {
        accessorFn: (row: any) => row?.recipient?.name,
        id: "recName",
        header: "Recipient Name",
      },
      {
        accessorFn: (row: any) => row?.recipient?.phone,
        id: "recPhone",
        header: "Recipient Phone",
      },
      {
        accessorFn: (row: any) => row?.sender?.name,
        id: "sName",
        header: "Sender Name",
      },
      {
        accessorFn: (row: any) => row?.sender?.phone,
        id: "sPhone",
        header: "Sender Phone",
      },
      {
        accessorFn: (row: any) => row?.destination?.name,
        id: "destination",
        header: "Source to Destination",
      },
      {
        accessorFn: (row: any) =>
          row?.charge === null ? "" : row?.charge?.amount,
        id: "charges",
        header: "Charge",
      },
      {
        accessorFn: (row: any) => row?.status?.name,
        id: "status",
        header: "Status",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "date",
        header: "Date",
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Parcels</Typography>
              <ChevronDoubleLeftIcon
                className={`${
                  hideFilter ? "block" : "block lg:hidden"
                } h-5 w-5 cursor-pointer`}
                onClick={() => handleFilterHide()}
              />
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={parcelData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <ParcelFilterPane
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              refNumber={refNumber}
              onRefNumChange={(e: any) => handleRefInput(e)}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ParcelList;
