import { Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { DateRangePicker } from "rsuite";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { ReactNode } from "react";
import BranchFilterSelect from "../../components/branchFilter";
import TownFilterSelect from "../../components/townSelect";

interface filterProps {
  onHide: () => void;
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  selectedBranch: any;
  onBranchChange: (value: any) => void;
  selectedTown: any;
  onTownChange: (value: any) => void;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;
  placement?: "bottom" | "left";
}

const MidrouteSalesFilter = ({
  onHide,
  defaultDates,
  handleDateChange,
  selectedBranch,
  onBranchChange,
  selectedTown,
  onTownChange
}: filterProps) => {
  const customRanges: Range[] = [
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: "This Week",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Last Week",
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: "This Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: "This Year",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: "Last Year",
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  return (
    <div className="px-3 border-l-[1px] border-gray-500 bg-white min-h-full pb-36">
      {/* header */}
      <div className="mb-2 pt-7 flex justify-between items-center">
        <Typography variant="h5" color="blue-gray">
          Filter
        </Typography>
        <ChevronDoubleRightIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => onHide()}
        />
      </div>

      <div className="mt-5 w-full">
        <p>Date Rage</p>
        <DateRangePicker
          className="w-full"
          placement="leftStart"
          value={defaultDates}
          onChange={(e: any) => handleDateChange(e)}
          ranges={customRanges}
        />
      </div>
      <div className="mt-5 w-full">
        <p>Branch</p>
        <BranchFilterSelect
          selectedBranch={selectedBranch}
          onBranchChange={(value) => onBranchChange(value)}
        />
      </div>

      <div className="mt-5 w-full">
        <p>Source Town</p>
        <TownFilterSelect
          selectedTown={selectedTown}
          onTownChange={(value) => onTownChange(value)}
        />
      </div>
    </div>
  );
};

export default MidrouteSalesFilter;
