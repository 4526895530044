export const stringToBase64 = (str) => {
  const base64String = btoa(str);
  return base64String;
};

export const formatPhoneNumber = (str) => {
  if (str.startsWith("0")) {
    return "233" + str.slice(1);
  } else if (str.startsWith("+")) {
    return str.slice(1);
  } else if (str.startsWith("233")) {
    return str;
  }

  return str;
};

export const formatPhoneNumber2 = (str) => {
  if (str.startsWith("0")) {
    return str.slice(1);
  } else if (str.startsWith("+233")) {
    return str.slice(4);
  } else if (str.startsWith("233")) {
    return str.slice(3);
  }

  return str;
};

export const redirect = (to) => {
  window.location = to;
};
