import {
  ChevronDoubleLeftIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import MidrouteSalesFilter from "./midrouteSalesFilter";
import { endOfDay, startOfMonth } from "date-fns";
import { getApiWithMs, rideMsDownloadApi } from "../../../../../utils/api";
import { globalVariables } from "../../../../../helpers/globarVars";
import { formatPhoneNumber } from "../../../../../utils/functions";
import { Button } from "@material-tailwind/react";
import StatsCard from "../../../fuelService/components/statsCard";

const MidrouteSales = () => {
  const [hideFilter, setHideFilter] = useState(false);
  const [isTableLoading, setTableLoadingState] = useState(false);
  const [passengersData, setPassengersData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [, setStatsPeriod] = useState(0);
  const [totalTicketPrice, setTotalTicketPrice] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [townId, setTownId] = useState(null);
  const [phoneNumber] = useState("");
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfMonth(new Date()),
    endOfDay(new Date()),
  ]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 12,
  });

  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const onBranchSelect = (value: any) => {
    setSelectedBranch(value);
    setBranchId(value?.value);
  };
  const onTownSelect = (value: any) => {
    setSelectedTown(value);
    setTownId(value?.value);
  };

  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 12 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfMonth(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 12 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => moment(row?.created_at).format("Do MMM YYYY"),
        id: "date",
        header: "Date",
        size: 120,
      },
      {
        accessorFn: (row: any) => moment(row?.created_at).format("hh:mm a"),
        id: "time",
        header: "Time",
        size: 120,
      },
      {
        accessorFn: (row: any) => `${row?.first_name} ${row?.last_name}`,
        id: "ticket_owner_name",
        header: "Ticket Owner Name",
        size: 150,
      },
      {
        accessorFn: (row: any) => row?.phone,
        id: "ticket_owner_phone",
        header: "Ticket Owner Phone",
        size: 150,
      },
      {
        accessorFn: (row: any) => row?.ticket_no,
        id: "ticket_number",
        header: "Ticket Number",
        size: 100,
      },
      {
        accessorFn: (row: any) => row?.bus?.reg_number,
        id: "vehicle_no",
        header: "Vehicle No.",
        size: 100,
      },
      {
        accessorFn: (row: any) => row?.driver?.phone,
        id: "driver_number",
        header: "Driver's Number",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.pickup?.name,
        id: "pickup",
        header: "Pick-up",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.branch?.name,
        id: "branch",
        header: "Branch",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.seat?.number,
        id: "seat",
        header: "Seat",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.payment_method,
        id: "mode_of_payment",
        header: "Mode of Payment",
        size: 120,
      },

      {
        accessorFn: (row: any) => row?.price,
        id: "ticket_price",
        header: "Ticket Price",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.amount_payable,
        id: "amount_paid",
        header: "Amount Paid",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          `${Number(row?.amount_payable - row?.price).toFixed(2)}`,
        id: "difference",
        header: "Difference",
        size: 120,
      },
    ],
    []
  );

  const fetchTableData = async (download = false) => {
    setTableLoadingState(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      defaultFilters.push({
        f: "boarding_type",
        o: "=",
        p: ["midway"],
      });
      if (phoneNumber) {
        defaultFilters.push({
          f: "user.phone",
          o: "contains",
          p: [formatPhoneNumber(phoneNumber)],
        });
      }
      if (branchId) {
        defaultFilters.push({
          f: "branch.id",
          o: "=",
          p: [branchId],
        });
      }

      if (townId) {
        defaultFilters.push({
          f: "source_town_id",
          o: "=",
          p: [townId],
        });
      }

      if (defaultDates) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      if (download) {
        const response = await rideMsDownloadApi(
          `${globalVariables.downloadPassengersRoute}?limit=${pagination.pageSize
          }&sorting=created_at:desc&offset=${offset}&filters=${JSON.stringify(
            defaultFilters
          )}`
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "midroute-data.xlsx");
        document.body.appendChild(link);
        setTableLoadingState(false);

        link.click();
      } else {
        const [listResponse, countResponse] = await Promise.all([
          await getApiWithMs(
            `${globalVariables.getPassengersRoute
            }?sorting=created_at:desc&limit=${pagination.pageSize
            }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
          ),
          await getApiWithMs(
            `${globalVariables.getPassengersCountsRoute
            }?filters=${JSON.stringify(defaultFilters)}`
          ),
        ]);

        setStatsPeriod(countResponse?.payload?.period || "");
        setTotalTicketPrice(countResponse?.payload?.total_ticket_price || 0);
        setTotalAmountPaid(countResponse?.payload?.total_amount_paid || 0);

        setPassengersData(listResponse?.payload?.items ?? []);
        setRowCount(listResponse?.payload?.total || 0);
        setTableLoadingState(false);
      }
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };

  useEffect(() => {
    fetchTableData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, pagination.pageIndex, pagination.pageSize, defaultDates, branchId, townId]);

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${hideFilter ? "" : "grid grid-cols-4"
            }  h-full overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Midroute Sales</Typography>
              <div className="flex items-center gap-5">
                <Button
                  onClick={() => {
                    fetchTableData(true);
                  }}
                  variant="gradient"
                  color="red"
                  size="sm"
                  className="flex items-center justify-center"
                >
                  Download <CloudArrowDownIcon className="h-5 w-5 ml-2" />
                </Button>
                <ChevronDoubleLeftIcon
                  className={`${hideFilter ? "block" : "block lg:hidden"
                    } h-5 w-5 cursor-pointer`}
                  onClick={() => handleFilterHide()}
                />
              </div>
            </div>

            <div className="lg:grid lg:grid-cols-4 mt-2 gap-5">
              <StatsCard
                label="Total Ticket Price"
                value={totalTicketPrice}
                loading={isTableLoading}
                type="currency"
              />
              <StatsCard
                label="Total Amount Paid"
                value={totalAmountPaid}
                loading={isTableLoading}
                type="currency"
              />
              <StatsCard
                label="Total Difference"
                value={Number(totalAmountPaid - totalTicketPrice)}
                loading={isTableLoading}
                type="currency"
              />
            </div>
            <div className="grid grid-cols-1 mt-5">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={passengersData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isTableLoading,
                    pagination,
                    density: "compact",
                    isLoading: isTableLoading,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
              } h-full overflow-y-auto`}
          >
            <MidrouteSalesFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              onBranchChange={onBranchSelect}
              selectedBranch={selectedBranch}
              onTownChange={onTownSelect}
              selectedTown={selectedTown}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MidrouteSales;
