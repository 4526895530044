import { Button, Typography } from "@material-tailwind/react";
import PageLayout from "../../../../components/layouts/pageLayout";
import { useState, useEffect, useMemo } from "react";
import {
  ChevronDoubleLeftIcon,
  CloudArrowDownIcon,
} from "@heroicons/react/24/outline";
import {
  fuelBackofficeDownloadApi,
  getFuelBackOfficeMSApi,
} from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";
import { MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import { formatPhoneNumber2 } from "../../../../utils/functions";
import moment from "moment";
import DiscountFilter from "./discountFilter";
const FuelCoupons = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [thePhoneNum, setThePhoneNum] = useState("");
  const [actualPhone, setActualPhone] = useState("");

  const [name, setName] = useState("");
  const [reference, setReference] = useState("");
  const [walletNumber, setWalletNumber] = useState("");

  // handle Filter hide
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  const handlePhoneInput = (e: any) => {
    setThePhoneNum(e.target.value);
    if (thePhoneNum.length > 1) {
      setActualPhone(e.target.value);
    }
  };

  const handleNameInput = (e: any) => {
    setName(e.target.value);
  };

  const handleReferenceInput = (e: any) => {
    setReference(e.target.value);
  };

  const handleWalletNumberInput = (e: any) => {
    setWalletNumber(e.target.value);
  };
  const fetchTableData = async (download = false) => {
    setIsFetching(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (defaultDates.length !== 0) {
        defaultFilters.push({
          f: "created_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }

      if (actualPhone) {
        defaultFilters.push({
          f: "owner.phone",
          o: "contains",
          p: [formatPhoneNumber2(actualPhone)],
        });
      }

      if (name) {
        defaultFilters.push({
          f: "owner.name",
          o: "contains",
          p: [name],
        });
      }

      if (reference) {
        defaultFilters.push({
          f: "coupon.reference",
          o: "=",
          p: [reference],
        });
      }
      if (walletNumber) {
        defaultFilters.push({
          f: "wallet_account_number",
          o: "=",
          p: [walletNumber],
        });
      }

      if (download) {
        const response = await fuelBackofficeDownloadApi(
          `${globalVariables.downloadFuelDiscounts}?limit=${
            pagination.pageSize
          }&sorting=created_at:desc&offset=${offset}&filters=${JSON.stringify(
            defaultFilters
          )}`
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "discount-data.xlsx");
        document.body.appendChild(link);
        setIsFetching(false);
        setIsLoading(false);
        link.click();
      } else {
        const response = await getFuelBackOfficeMSApi(
          `${globalVariables.getFuelDiscounts}?limit=${
            pagination.pageSize
          }&sorting=created_at:desc&offset=${offset}&filters=${JSON.stringify(
            defaultFilters
          )}`
        );
        console.log(response);
        setData(response?.payload?.items || []);
        setRowCount(response?.payload?.total);
        setIsFetching(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      throw new Error("Error fetching data");
    }
  };
  // fetch data
  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualPhone,
    name,
    reference,
    walletNumber,
  ]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.owner?.name,
        id: "owner",
        header: "Coupon Owner",
      },
      {
        accessorFn: (row: any) => `${row?.currency} ${row?.amount}`,
        id: "amount",
        header: "Discount Amount",
      },
      {
        accessorFn: (row: any) => `${row?.wallet_account_number}`,
        id: "wallet_number",
        header: "Wallet Number",
      },
      {
        accessorFn: (row: any) => row?.owner?.phone,
        id: "phone",
        header: "Owner Phone Number",
        size: 120,
      },
      {
        accessorFn: (row: any) => row?.coupon?.reference,
        id: "reference",
        header: "Serial No.",
        size: 120,
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm a"),
        id: "discount_credited",
        header: "Discount Credited On",
        size: 200,
      },
    ],
    []
  );

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <Typography variant="h4">Discounts</Typography>

              <div className="flex items-center gap-5">
                <Button
                  onClick={() => {
                    fetchTableData(true);
                  }}
                  variant="gradient"
                  color="red"
                  size="sm"
                  className="flex items-center justify-center"
                >
                  Download <CloudArrowDownIcon className="h-5 w-5 ml-2" />
                </Button>
                <ChevronDoubleLeftIcon
                  className={`${
                    hideFilter ? "block" : "block lg:hidden"
                  } h-5 w-5 cursor-pointer`}
                  onClick={() => handleFilterHide()}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={data}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <DiscountFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              nameInput={name}
              onNameInputChange={(e) => handleNameInput(e)}
              phoneInput={thePhoneNum}
              onPhoneInputChange={(e) => handlePhoneInput(e)}
              referenceInput={reference}
              onReferenceInputChange={(e) => handleReferenceInput(e)}
              walletNumberInput={walletNumber}
              onWalletNumberInputChange={handleWalletNumberInput}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default FuelCoupons;
