import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import logoImg from "../../images/logo.png";
import { Tooltip, Typography } from "@material-tailwind/react";
import ServiceSelector from "../general/serviceSelector";
import ServiceContext from "../../context/serviceContext";
import {
  busRoutes,
  hiringRoutes,
  parcelRoutes,
  fuelRoutes,
} from "../../routes/routeList";
import { Link, useNavigate, useLocation } from "react-router-dom";

const CustomSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [service, setService] = useState<string>("");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isActiveMenu = (pathname: string) => {
    return location.pathname.includes(pathname);
  };

  const handleActiveTab = (route: string) => {
    navigate(route);
  };

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <ServiceContext.Provider value={{ service, setService }}>
      <Sidebar collapsed={isCollapsed}>
        <Menu className="bg-white h-full">
          {/* logo */}
          <div className="p-3 flex items-center gap-3 w-full">
            <Link className="w-full" to="#">
              <img src={logoImg} alt="logo" />
            </Link>
            <Typography
              className={`${isCollapsed ? "hidden" : ""}`}
              variant="h5"
            >
              Analytics
            </Typography>
            <div
              className={`${
                isCollapsed ? "hidden" : "flex justify-end items-center w-full"
              }`}
            >
              <ChevronDoubleLeftIcon
                className="w-5 h-5 cursor-pointer"
                onClick={() => handleCollapse()}
              />
            </div>
          </div>
          <ChevronDoubleRightIcon
            className={`${
              isCollapsed
                ? "w-5 h-5 cursor-pointer my-3 flex mr-auto ml-auto"
                : "hidden"
            }`}
            onClick={() => handleCollapse()}
          />

          <div className={`${isCollapsed ? "hidden" : "px-3 w-full my-5"}`}>
            <ServiceSelector />
          </div>

          {service === "parcel"
            ? parcelRoutes.map((menu: any, i: number) => (
                <Tooltip key={i} content={menu.title} placement="right-end">
                  <MenuItem
                    onClick={() => handleActiveTab(menu.path)}
                    icon={menu.icon}
                    className={`${
                      isActiveMenu(menu.path)
                        ? "border-l-[5px] border-l-oya-ghana-red bg-oya-ghana-red bg-opacity-[5%]"
                        : ""
                    }`}
                  >
                    {menu.title}
                  </MenuItem>
                </Tooltip>
              ))
            : service === "hiring"
            ? hiringRoutes.map((menu: any, i: number) => (
                <Tooltip key={i} content={menu.title} placement="right-end">
                  <MenuItem
                    onClick={() => handleActiveTab(menu.path)}
                    icon={menu.icon}
                    className={`${
                      isActiveMenu(menu.path)
                        ? "border-l-[5px] border-l-oya-ghana-red bg-oya-ghana-red bg-opacity-[5%]"
                        : ""
                    }`}
                  >
                    {menu.title}
                  </MenuItem>
                </Tooltip>
              ))
            : service === "bus"
            ? busRoutes.map((menu: any, i: number) => (
                <Tooltip key={i} content={menu.title} placement="right-end">
                  <MenuItem
                    onClick={() => handleActiveTab(menu.path)}
                    icon={menu.icon}
                    className={`${
                      isActiveMenu(menu.path)
                        ? "border-l-[5px] border-l-oya-ghana-red bg-oya-ghana-red bg-opacity-[5%]"
                        : ""
                    }`}
                  >
                    {menu.title}
                  </MenuItem>
                </Tooltip>
              ))
            : service === "fuel"
            ? fuelRoutes.map((menu: any, i: number) => (
                <Tooltip key={i} content={menu.title} placement="right-end">
                  <MenuItem
                    onClick={() => handleActiveTab(menu.path)}
                    icon={menu.icon}
                    className={`${
                      isActiveMenu(menu.path)
                        ? "border-l-[5px] border-l-oya-ghana-red bg-oya-ghana-red bg-opacity-[5%]"
                        : ""
                    }`}
                  >
                    {menu.title}
                  </MenuItem>
                </Tooltip>
              ))
            : ""}
        </Menu>
      </Sidebar>
    </ServiceContext.Provider>
  );
};

export default CustomSideBar;
