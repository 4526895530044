import { Input, Typography } from "@material-tailwind/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { globalVariables } from "../../../../../../helpers/globarVars";
import { getApi, getApiWithMs } from "../../../../../../utils/api";
import PageLayout from "../../../../../../components/layouts/pageLayout";
import {
  CloudArrowDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";

const DriverStatement = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [driversData, setDriversData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState("");

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        if (phoneNumber) {
          const response = await getApi(
            `${globalVariables.driverStatement}?q=${encodeURIComponent(
              phoneNumber
            )}`
          );
          setDriversData(response?.data ?? []);
          setRowCount(response?.data?.length);
          setIsFetching(false);
          setIsLoading(false);
        } else {
          const response = await getApi(`${globalVariables.driverStatement}`);
          setDriversData(response?.data ?? []);
          setRowCount(response?.data?.length);
          setIsFetching(false);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };
    fetchTableData();
  }, [phoneNumber]);

  // handle download
  const handleDownload = (theId: any) => {
    console.log(theId);
    try {
      const response = getApiWithMs(
        `${globalVariables.exportDrivers}/${theId}/trips-export`
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.ice1_phone,
        id: "primary",
        header: "ICE 1",
      },
      {
        accessorFn: (row: any) => row?.ice2_phone,
        id: "secondary",
        header: "ICE 2",
      },
      {
        accessorFn: (row: any) => row?.verified_at,
        id: "verification",
        header: "Verification Date",
      },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
        id: "registration",
        header: "Registration Date",
      },
      {
        accessorFn: (row: any) => (
          <button
            className="flex items-center gap-2 border-[1px] border-oya-ghana-red px-3 py-1 text-oya-ghana-red"
            onClick={() => handleDownload(row?.id)}
          >
            <CloudArrowDownIcon className="w-5 h-5" />
            Download
          </button>
        ),
        id: "statement",
        header: "Statement",
      },
    ],
    []
  );

  return (
    <Fragment>
      <PageLayout>
        <div className="h-full p-[20px] bg-[#f5f5f5] overflow-hidden">
          <div className="h-full overflow-hidden">
            <div className="h-full overflow-y-auto pr-4 pb-20">
              <div className="flex gap-4 items-center mb-5">
                <Typography variant="h4">Statements / Drivers</Typography>
              </div>

              <div className="flex justify-end mb-3 w-full">
                <div>
                  <Input
                    type="text"
                    placeholder="Search phone or user name"
                    variant="static"
                    value={phoneNumber}
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                    icon={<MagnifyingGlassIcon className="w-5 h-5" />}
                  />
                </div>
              </div>
              <div className="mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={driversData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  state={{
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default DriverStatement;
