import { useState, useEffect, useMemo } from "react";
import TravellersFilter from "./travellersFilter";
import {
  ArrowDownTrayIcon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/outline";
import PageLayout from "../../../../../components/layouts/pageLayout";
import { getApiWithMs, getDownloadApiWithMs } from "../../../../../utils/api";
import { globalVariables } from "../../../../../helpers/globarVars";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { endOfDay, startOfDay } from "date-fns";
import { formatDateRange } from "../../../../../helpers/utils";
import { Spin } from "antd";
import { Box, Typography } from "@mui/material";
import PassengerTripsDrawer from "../../components/PassengerTripsDrawer";
interface User {
  id: string;
  name: string;
  phone: string;
  ice_phone: string;
}

interface Travelers {
  trips: number;
  user: User;
}

const TravellersList = () => {
  const [hideFilter, setHideFilter] = useState<Boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedUser] = useState<number | undefined>();
  const [branchId, setBranchId] = useState(null);
  const [selectedLoadingPoint, setSelectedLoadingPoint] = useState("");
  const [loadingPointId, setLoadingPointId] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState("");
  const [routeId, setRouteId] = useState(null);
  const [openViewPassengersDrawer, setOpenViewPassengersDrawer] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [travellersData, setTravellersData] = useState<Travelers[]>([]);
  const [travellerTripsData, setTravellerTripsData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [entityID, setEntityID] = useState("");

  const [rowCount, setRowCount] = useState(0);
  const [defaultDates, setDefaultDates] = useState<any>([
    startOfDay(new Date()),
    endOfDay(new Date()),
  ]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [passenger_pagination, setPassengerPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleViewPassengerTripsDrawer = (entityId: string) => {
    setEntityID(entityId);
    getPassengerTrips(entityId);
    setOpenViewPassengersDrawer(!openViewPassengersDrawer);
  };
  const getPassengerTrips = async (entityId: string) => {
    setIsLoading(true);
    try {
      const offset =
        passenger_pagination.pageIndex * passenger_pagination.pageSize;

      const defaultFilters = [];

      if (branchId) {
        defaultFilters.push({ f: "branch_id", o: "=", p: [branchId] });
      }
      if (loadingPointId) {
        defaultFilters.push({
          f: "station_route_id",
          o: "=",
          p: [loadingPointId],
        });
      }
      if (selectedUser) {
        defaultFilters.push({
          f: "user.id",
          o: "=",
          p: [selectedUser],
        });
      }
      if (defaultDates && defaultDates.length) {
        defaultFilters.push({
          f: "departures_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }
      if (routeId) {
        defaultFilters.push({
          f: "route.id",
          o: "=",
          p: [routeId],
        });
      }
      console.log("here");

      const response = await getApiWithMs(
        `${globalVariables.getTravellersRoute}/${entityId}/trips?sorting=departures_at:desc&limit=${passenger_pagination.pageSize}&offset=${offset}`
      );
      setTravellerTripsData(response?.payload?.items || []);
      setTotal(response?.payload?.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFilterHide = () => {
    setHideFilter(!hideFilter);
  };

  const handlePassengerPagination = (entityId: string) => {
    setPassengerPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));

    getPassengerTrips(entityId);
  };

  // on date change
  const handleCalendarChange = (selectedDate: any) => {
    if (!selectedDate) {
      setDefaultDates([]);
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // handle onBranch select
  const onBranchSelect = (value: any) => {
    setSelectedBranch(value);
    setBranchId(value?.value);
  };

  const onRouteChange = (value: any) => {
    setSelectedRoute(value);
    setRouteId(value?.value || null);
  };

  // handle onPoint select
  const onPointSelect = (value: any) => {
    setSelectedLoadingPoint(value);
    setLoadingPointId(value?.value);
  };
  const fetchTableData = async (download = false) => {
    setIsLoading(true);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;

      const defaultFilters = [];

      if (branchId) {
        defaultFilters.push({ f: "branch_id", o: "=", p: [branchId] });
      }
      if (loadingPointId) {
        defaultFilters.push({
          f: "station_route_id",
          o: "=",
          p: [loadingPointId],
        });
      }
      if (selectedUser) {
        defaultFilters.push({
          f: "user.id",
          o: "=",
          p: [selectedUser],
        });
      }
      if (defaultDates && defaultDates.length) {
        defaultFilters.push({
          f: "departures_at",
          o: "between",
          p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
        });
      }
      if (routeId) {
        defaultFilters.push({
          f: "route.id",
          o: "=",
          p: [routeId],
        });
      }
      if (download) {
        const response = await getDownloadApiWithMs(
          `${globalVariables.exportTravellers}?limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${btoa(JSON.stringify(defaultFilters))}`
        );

        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );

        const link = document.createElement("a");
        link.href = downloadUrl;

        link.setAttribute("download", "travellers.xlsx");
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
      } else {
        const response = await getApiWithMs(
          `${globalVariables.getTravellersRoute}?limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        setTravellersData(response?.payload?.items || []);
        setRowCount(response?.payload?.total);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      // notification.error({
      //   message: error?.data?.message || "Failed to download data",
      // });
    }
  };

  // fetch data
  useEffect(() => {
    fetchTableData(false);

    // eslint-disable-next-line
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    passenger_pagination.pageIndex,
    passenger_pagination.pageSize,
    defaultDates,
    branchId,
    loadingPointId,
    selectedUser,
    routeId,
  ]);

  // table columns
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorFn: (row: any) => row?.user?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.user?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.user?.ice_phone,
        id: "iceContact",
        header: "ICE",
      },
      {
        accessorFn: (row: any) => row?.trips,
        id: "trips",
        header: "Trips",
        size: 100,
      },
      {
        id: "more",
        header: "Actions",
        accessorFn: (row: any) => `${row?.user?.id}`,
        Cell: (dt: any) => (
          <Box>
            <button
              className="px-2 py-2 bg-green-500 text-white flex gap-2 items-center disabled:bg-gray-300 disabled:cursor-not-allowed"
              onClick={() =>
                handleViewPassengerTripsDrawer(dt.cell?.getValue())
              }
            >
              View Trips
            </button>
          </Box>
        ),
        size: 80,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //handleDataExport
  const handleDataExport = () => {
    fetchTableData(true);
  };

  return (
    <PageLayout>
      <div className="h-screen relative bg-[#f5f5f5] overflow-hidden">
        <div
          className={`${
            hideFilter ? "" : "grid grid-cols-4 gap-4"
          } h-screen overflow-hidden`}
        >
          <div className="col-span-3 h-full overflow-y-auto p-[20px]">
            <div className="flex justify-between items-center">
              <div className="flex justify-between items-center">
                <Typography variant="h5">
                  Travellers{" "}
                  <Typography variant="caption">
                    {`(${formatDateRange(defaultDates[0], defaultDates[1])})`}
                  </Typography>
                </Typography>
              </div>

              <div className="flex justify-between items-center">
                <button
                  className="mr-5 px-3 py-2 bg-oya-ghana-red text-white flex gap-2 items-center mt-3 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  onClick={() => handleDataExport()}
                  disabled={isLoading}
                >
                  {isLoading && <Spin />}
                  <ArrowDownTrayIcon className="h-5 w-5" />
                  <span>Download</span>
                </button>
                <ChevronDoubleLeftIcon
                  className={`${
                    hideFilter ? "block" : "block lg:hidden"
                  } h-5 w-5 cursor-pointer`}
                  onClick={() => handleFilterHide()}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="mt-3 mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={travellersData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isLoading,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className={`${
              hideFilter ? "lg:hidden" : "lg:relative absolute right-0 z-10"
            } h-full`}
          >
            <TravellersFilter
              onHide={handleFilterHide}
              defaultDates={defaultDates}
              handleDateChange={handleCalendarChange}
              selectedBranch={selectedBranch}
              onBranchChange={(value) => onBranchSelect(value)}
              isLoadingPointActive={!selectedBranch}
              branchId={branchId}
              selectedLoadingPoint={selectedLoadingPoint}
              onPointChange={(value) => onPointSelect(value)}
              onRouteChange={(value) => onRouteChange(value)}
              isRouteActive={true}
              selectedRoute={selectedRoute}
            />
          </div>
        </div>
      </div>
      <PassengerTripsDrawer
        handleDrawerClose={function (): void {
          setOpenViewPassengersDrawer(false);
        }}
        isOpened={openViewPassengersDrawer}
        isLoading={isLoading}
        trips={travellerTripsData}
        total={total}
        entityId={entityID}
        handlePagination={(userId) => {
          handlePassengerPagination(userId);
        }}
      />
    </PageLayout>
  );
};

export default TravellersList;
