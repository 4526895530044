import Select from "react-select";
import { useState, useEffect } from "react";
import { getApiWithMs } from "../../../../utils/api";
import { globalVariables } from "../../../../helpers/globarVars";

interface chooseBranchProp {
    selectedBranch: any;
    selectedCompany?: any;
    onBranchChange: (value: any) => void;
}

const BranchFilterSelect = ({
    selectedBranch,
    onBranchChange,
    selectedCompany,
}: chooseBranchProp) => {
    const [isFetching, setIsFetching] = useState(false);
    const [branchesList, setBranchesList] = useState([]);
    const [branchNameInput, setBranchInput] = useState<any>("");

    const handleBranchChange = (value: any) => {
        onBranchChange(value || {});
    };

    const handleInputChange = (e: any) => {
        setBranchInput(e);
    };

    // fetch branches
    useEffect(() => {
        const fetchBranches = async () => {
            interface Filter {
                f: string;
                o: string;
                p: string[];
            }

            let defaultFilters: Filter[] = [];

            // Default filters
            defaultFilters.push({
                f: "name",
                o: "contains",
                p: [branchNameInput],
            });

            if (selectedCompany) {
                defaultFilters.push({
                    f: "company.id",
                    o: "=",
                    p: [selectedCompany],
                });
            }

            setIsFetching(true);

            try {
                const response = await getApiWithMs(
                    `${
                        globalVariables.getBranchesRoute
                    }?limit=500&filters=${JSON.stringify(defaultFilters)}`
                );
                const mappedBranches = response?.payload?.items.map(
                    (branch: any, i: number) => ({
                        value: branch?.id,
                        label: branch?.name,
                    })
                );
                setBranchesList(mappedBranches);
                setIsFetching(false);
            } catch (error) {
                console.log(error);
                throw new Error("Error fetching data");
            }
        };

        fetchBranches();
    }, [branchNameInput, selectedCompany]);

    return (
        <>
            <p>Filter by branch</p>
            <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a branch"
                isSearchable={true}
                name="branches"
                options={branchesList}
                isLoading={isFetching}
                value={selectedBranch}
                onChange={handleBranchChange}
                onInputChange={handleInputChange}
                isClearable
            />
        </>
    );
};

export default BranchFilterSelect;
