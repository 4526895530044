import { Input, Typography } from "@material-tailwind/react";
import { Fragment, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { globalVariables } from "../../../../../../helpers/globarVars";
import { getApiWithMs } from "../../../../../../utils/api";
import PageLayout from "../../../../../../components/layouts/pageLayout";
import {
  CloudArrowDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import { formatPhoneNumber } from "../../../../../../utils/functions";

const PassengerStatement = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [passengersData, setPassengersData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // fetch data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsFetching(true);
      try {
        const offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (phoneNumber !== "") {
          defaultFilters.push({
            f: "user.phone",
            o: "contains",
            p: [formatPhoneNumber(phoneNumber)],
          });
        }

        const response = await getApiWithMs(
          `${
            globalVariables.passengerStatement
          }?sorting=created_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        setPassengersData(response?.payload?.items ?? []);
        setRowCount(response?.payload?.total);
        setIsFetching(false);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        throw new Error("Error fetching data");
      }
    };
    fetchTableData();
  }, [phoneNumber, pagination.pageIndex, pagination.pageSize]);

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row: any) => row?.id,
        id: "id",
        header: "ID",
      },
      {
        accessorFn: (row: any) => row?.user?.name,
        id: "name",
        header: "Name",
      },
      {
        accessorFn: (row: any) => row?.user?.phone,
        id: "phone",
        header: "Phone",
      },
      {
        accessorFn: (row: any) => row?.user?.ice1_phone,
        id: "primary",
        header: "ICE 1",
      },
      {
        accessorFn: (row: any) => row?.user?.ice2_phone,
        id: "secondary",
        header: "ICE 2",
      },
      // {
      //   accessorFn: (row: any) => row?.verified_at,
      //   id: "verification",
      //   header: "Verification Date",
      // },
      {
        accessorFn: (row: any) =>
          moment(row?.created_at).format("Do MMM YYYY hh:mm a"),
        id: "registration",
        header: "Registration Date",
      },
      {
        accessorFn: (row: any) => (
          <button
            className="flex items-center gap-2 border-[1px] border-oya-ghana-red px-3 py-1 text-oya-ghana-red cursor-not-allowed"
            disabled
          >
            <CloudArrowDownIcon className="w-5 h-5" />
            Download
          </button>
        ),
        id: "statement",
        header: "Statement",
      },
    ],
    []
  );

  return (
    <Fragment>
      <PageLayout>
        <div className="h-full py-[20px] pl-[20px] bg-[#f5f5f5] overflow-hidden">
          <div className="h-full overflow-hidden">
            <div className="h-full overflow-y-auto pr-4 pb-20">
              <div className="flex gap-4 items-center mb-5">
                <Typography variant="h4">Statements / Passengers</Typography>
              </div>

              <div className="flex justify-end mb-3 w-full">
                <div>
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    variant="static"
                    value={phoneNumber}
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                    icon={<MagnifyingGlassIcon className="w-5 h-5" />}
                  />
                </div>
              </div>
              <div className="mb-20">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={passengersData}
                  enableRowSelection={false}
                  rowCount={rowCount}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    showProgressBars: isFetching,
                    pagination,
                    density: "compact",
                    isLoading: isLoading,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default PassengerStatement;
